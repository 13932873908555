import React, { Component } from "react";
import axios from 'axios';
import { baseURL } from 'constants.js';
import cookie from 'react-cookies'
import NotificationSystem from "react-notification-system";
import { style,pillSuccess,pillDanger,pillInfo,pillWarning  } from "variables/Variables.jsx";
import { Modal } from "react-bootstrap";
import CustomButton from 'components/CustomButton/CustomButton.jsx';
import DataTable from "react-data-table-component";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import i18next from 'i18next';
import StatusComponent from "components/StatusLabelComponent/StatusComponent"
import {LightTooltip} from "components/CustomTooltip"

function CancelLeaveModal({ onCancelLeave, ...props }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {i18next.t('leave_management.cancel_leave')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 id="deleteBody">
          {i18next.t('leave_management.cancel_request_check')}
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton onClick={onCancelLeave}>  {i18next.t('modal_buttons.yes')}</CustomButton>
        <CustomButton onClick={props.onHide}>{i18next.t('modal_buttons.close')}</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}

class MyRequestsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      cancelLeaveModalShow: false,
      status: 'canceled',

      requestIndexToCancel: -1,
      requestToCancel: '',

      requestIndexToDownload: -1,
      requestToDownload: '',

      columns: [
        {
          name: i18next.t('table_headers.type'),
          selector: 'type'
        },
        {
          name: i18next.t('table_headers.start_date'),
          selector: 'startDate'
        },
        {
          name: i18next.t('table_headers.end_date'),
          selector: 'stopDate'
        },
        {
          name: i18next.t('status'),
          selector: 'status',
          grow:1.2
        },
        {
          name: i18next.t('table_headers.additional_message'),
          selector: 'reasonMessage'
        },
        {
          name: i18next.t('table_headers.actions'),
          selector: 'actions'
        }

      ],
      rows: [],
      filterText: "",
      filteredItems: [],
      loadedData: false
    };
    this.setCancelLeaveModalShow = this.setCancelLeaveModalShow.bind(this);
    this.onCancelLeave = this.onCancelLeave.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.subHeaderComponent = this.getSubHeaderComponent.bind(this);
  }

  setCancelLeaveModalShow() {
    this.setState({ cancelLeaveModalShow: !this.state.cancelLeaveModalShow });
    return this.state.cancelLeaveModalShow;
  }

  componentDidMount() {
    setTimeout(() => {
      let tokenValue = cookie.load("token");
      let userID = cookie.load("userData").id;
      let axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenValue,
        }
      };

      axios.get(baseURL + '/leaves_user/' + userID, axiosConfig)
        .then(response => {
          if (response && response.data) {
            let resData = this.state;
            resData.rows = response.data;
            for (let i = 0; i < resData.rows.length; i++) {
              resData.rows[i]['statusString'] =  resData.rows[i].status
              resData.rows[i]['actions'] = this.buildRequestsTableActions(i, resData.rows[i].id,resData.rows[i].status)
              resData.rows[i]['status'] =  <StatusComponent status={resData.rows[i].status}></StatusComponent>
              resData.rows[i].type=i18next.t(`filter_component.${resData.rows[i].type}`)
            }
            this.setState({
              data: resData,
              filteredItems: this.state.rows,
              loadedData: true
            })
          }
        })
        .catch(error => {
					if(error.response!==undefined)
						this.handleNotificationClick(i18next.t(error.response.data.message),"error")
				});

    }, 200);
  }
  setStatusColor(data) {
    switch (data) {
      case 'rejected':
        return (
          <span style={pillDanger}>{i18next.t(data+"_table")}</span >
        );
      case 'accepted':
        return (
          <span style={pillSuccess} >{i18next.t(data+"_table")}</span >
        );
      case 'pending':
        return (
          <span style={pillWarning}>{i18next.t(data+"_table")}</span >
        );
      case 'canceled':
        return (
          <span style={pillInfo}>{i18next.t(data+"_table")}</span >
        );
      default: return (
        <span style={pillDanger}>{i18next.t("undefined")}</span >
      );
    }

  }
  buildRequestsTableActions(index, id,status) {
    return (
      <div>
        {status==='pending'?
        <i className="fas fa-window-close" aria-hidden="true" onClick={() => this.verifyCancelRequest(index)}></i>:null}
        <LightTooltip title="Download PDF" >
          <i style={{ marginLeft: 20 }} className="fas fa-file-download" onClick={() => this.getToDownloadRequest(index, id)}>
          </i>
          </LightTooltip>
      </div>
    )
  }

  verifyCancelRequest = (index) => {
    this.setState({
      requestIndexToCancel: index
    })
    let requestId = this.state.rows[index].id;
    this.setState({
      requestToCancel: requestId
    })
    this.setCancelLeaveModalShow();
  };

  onCancelLeave() {
    const leave = {
      status: this.state.status
    }
    let tokenValue = cookie.load("token");
    axios({
      method: 'put',
      url: baseURL + '/leaves_status/' + this.state.requestToCancel,
      data: leave,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue
      }
    })
      .then(response => {
        this.handleNotificationClick("Canceled successfully!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 700);
      })
      .catch(error => {
        if(error.response!==undefined)
          this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      });
  }

  getToDownloadRequest = (index, id) => {
    this.setState({
      requestIndexToDownload: index
    })
    let request = this.state.rows[index]
    this.setState({
      requestToDownload: request
    })
    let tokenValue = cookie.load("token");
    axios({
      method: 'get',
      url: baseURL + '/export_as_pdf/' + id,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue
      }
    })
      .then((response) => {
        window.open(response.data.downloadLink);
      });
  };

  handleNotificationClick = (msg, level) => {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        msg
      ),
      level: level,
      position: "tr",
      autoDismiss: 15
    });
  };
  getSubHeaderComponent = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder={i18next.t("search")}
          onChange={(e) => {
            let newFilterText = e.target.value;
            this.state.filteredItems = this.state.data.rows.filter(
              (item) =>
                (item.statusString && item.statusString.toLowerCase().includes(newFilterText.toLowerCase())))
            this.setState({ filterText: newFilterText });
            if (newFilterText === "")
              this.state.filteredItems = this.state.data.rows;
          }}
        />
      </div>
    );
  }
  render() {
    return (
      <div className="content"  style={{ minHeight:'70vh' }}>
        <NotificationSystem ref="notificationSystem" style={style} />
        <CancelLeaveModal onCancelLeave={this.onCancelLeave}
          show={this.state.cancelLeaveModalShow}
          onHide={this.setCancelLeaveModalShow} />
        {this.state.loadedData ?
          <DataTable
            columns={this.state.columns}
            data={this.state.filteredItems}
            pagination
            keyField="Leave_Id"
            defaultSortField='startDate'
            defaultSortAsc={false}
            striped
            responsive
            subHeaderComponent={this.getSubHeaderComponent()}
            subHeader
            noHeader
          /> :
            <LoadingComponent />}
      </div>
    )
  }
}

export default MyRequestsTable;