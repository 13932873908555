import React from "react";
import { Modal } from "react-bootstrap";
import "../../assets/css/Employees.css";
import i18next from "i18next";
import CustomButton from "components/CustomButton/CustomButton";
export default function DeleteUserModal({ onDelete, ...props }) {
	return (
		<Modal style={{ margin: 0 }} {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("active_employees.delete_user")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3 id="deleteBody">{i18next.t("active_employees.delete_user_check")}</h3>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton id="" onClick={onDelete}>
					{i18next.t("modal_buttons.yes")}
				</CustomButton>
				<CustomButton onClick={props.onHide}>{i18next.t("modal_buttons.close")}</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}