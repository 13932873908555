import React from "react";
import { Modal } from "react-bootstrap";
import "../../assets/css/Employees.css";
import UpdateEmployee from "../Admin/UpdateEmployee";
import i18next from "i18next";

export default function UpdateUserModal(props) {
	return (
		<Modal {...props} size="lg" id="modal-form" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header style={{ marginLeft: 15, marginRight: 15 }} closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("active_employees.update_user")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<UpdateEmployee
					userId={props.user.id}
					firstName={props.user.firstName}
					lastName={props.user.lastName}
					email={props.user.email}
					department={props.user.department}
					currentDaysLeft={props.user.currentDaysLeft}
					lastYearDays={props.user.lastYearDays}
					userRole={props.user.userRole}
					supervisor={props.user.supervisor}
					hireDate={props.user.hireDate}
					onExit={props.onHide}
					isManager={props.user.isManager}
					supervisors={props.supervisors}
					departments={props.departments}
				></UpdateEmployee>
			</Modal.Body>
		</Modal>
	);
}
