import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import SettingsForm from "components/Admin/SettingsForm";
import SettingsTable from "components/Admin/SettingsTable";
import { Tabs, TabLink, TabContent } from "react-tabs-redux";
import "../../assets/css/EmployeesNavbar.css";
import i18next from "i18next";
import DepartmentsForm from "./DepartmentsForm";
import DepartmentsTable from "./DepartmentsTable";
class Settings extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="page-container">
				<ul className="nav nav-tabs"></ul>
				<Tabs>
					<div id="divTabs">
						<TabLink className="tabLink" to="settings">
							{i18next.t("settings_page.tab_title")}
						</TabLink>
						{/* TODO uncomment when requests are ready */}
						<TabLink className="tabLink" to="departments_config">
							Departments configuration
						</TabLink>
					</div>

					<TabContent for="settings">
						<Grid fluid>
							<Row>
								<Col md={6}>
									<div style={{ marginTop: 35 }}>
										<SettingsTable></SettingsTable>
									</div>
								</Col>
								<Col md={6}>
									<div>
										<SettingsForm></SettingsForm>
									</div>
								</Col>
							</Row>
						</Grid>
					</TabContent>
					<TabContent for="departments_config">
						<Grid fluid>
							<Row>
								<Col md={6}>
									<div style={{ marginTop: 35 }}>
										<DepartmentsTable></DepartmentsTable>
									</div>
								</Col>
								<Col md={6}>
									<div>
										<DepartmentsForm></DepartmentsForm>
									</div>
								</Col>
							</Row>
						</Grid>
					</TabContent>
				</Tabs>
			</div>
		);
	}
}

export default Settings;
