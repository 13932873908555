
import React, { Component } from "react";
import { pillSuccess, pillDanger, pillInfo, pillWarning } from "variables/Variables.jsx";
import i18next from 'i18next';
class StatusComponent extends Component {
    constructor(props) {
        super(props);
        this.setStatusColor = this.setStatusColor.bind(this);
    }
    setStatusColor() {
        switch (this.props.status) {
            case 'rejected':
                return (
                    <span style={pillDanger}>{i18next.t("status_component.rejected_table")}</span >
                );
            case 'accepted':
                return (
                    <span style={pillSuccess} >{i18next.t("status_component.accepted_table")}</span >
                );
            case 'pending':
                return (
                    <span style={pillWarning}>{i18next.t("status_component.pending_table")}</span >
                );
            case 'canceled':
                return (
                    <span style={pillInfo}>{i18next.t("status_component.canceled_table")}</span >
                );
            case 'on leave':
                return (
                    <span style={pillDanger}>{i18next.t('status_component.on_leave').toUpperCase()}</span >
                );
            case 'working':
                return (
                    <span style={pillSuccess}>{i18next.t('status_component.working').toUpperCase()}</span >
                );
            default:return (
                <span style={pillDanger}>{i18next.t('status_component.undefined').toUpperCase()}</span >
            );
        }

    }
    render() {

        return (
            <div>
                {this.setStatusColor()}
            </div>
        )
    }
}
export default StatusComponent;