import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import DataTable from "react-data-table-component";
import FiltersComponent from "../Admin/FiltersComponent";
import moment from "moment";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import i18next from "i18next";
import SubHeaderNoExport from "components/SubHeaderTable/SubHeaderNoExport";

const columns = [
	{
		name: i18next.t("table_headers.start_date"),
		selector: "startDate",
	},
	{
		name: i18next.t("table_headers.end_date"),
		selector: "endDate",
	},
	{
		name: i18next.t("table_headers.type"),
		selector: "type",
	},
	{
		name: i18next.t("meal_vouchers.days"),
		selector: "workingDays",
	},
];
const ExpandableComponent = ({ data }) => (
	<div style={{ border: "2px solid lightgrey", marginLeft: 50, marginRight: 50, marginBottom: 20 }}>
		<DataTable columns={columns} data={data.leaves} keyField="Leave_Id" responsive noHeader />
	</div>
);

class MealVoucher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			cancelLeaveModalShow: false,

			requestIndexToCancel: -1,
			requestToCancel: "",
			status: "canceled",

			requestIndexToDownload: -1,
			requestToDownload: "",

			columns: [
				{
					name: i18next.t("table_headers.last_name"),
					selector: "lastName",
				},
				{
					name: i18next.t("table_headers.first_name"),
					selector: "firstName",
				},
				{
					name: i18next.t("meal_vouchers.leave_days"),
					selector: "workingDays",
				},
				{
					name: i18next.t("meal_vouchers.work_days"),
					selector: "workedDays",
				},
			],
			rows: [],
			filterText: "",
			filteredItems: [],
			downloadLink: "",
			dataToDownload: null,
			loadedData: false,
		};

		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.updateData = this.updateData.bind(this);
		this.loadData = this.loadData.bind(this);
		this.updateData = this.updateData.bind(this);
	}
	setCancelLeaveModalShow() {
		this.setState({ cancelLeaveModalShow: !this.state.cancelLeaveModalShow });
		return this.state.cancelLeaveModalShow;
	}
	loadData(start, end, type, status) {
		let tokenValue = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/meal_vouchers",
			data: {
				type: "all",
				startDate: start,
				endDate: end,
				status: "accepted",
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response) {
					let filteredItemsTemp = response.data;
					for (let i = 0; i < response.data.length; i++) {
						for (let j = 0; j < response.data[i].leaves.length; j++) {
							filteredItemsTemp[i].leaves[j].type = i18next.t(`filter_component.${filteredItemsTemp[i].leaves[j].type}`);
						}
					}
					this.setState({
						filteredItems: filteredItemsTemp,
						data: response.data,
						filterText: '',
					});
				}
			})
			.catch((error) => {
				// if (error.response.data)
				//   error.response.data.message ?
				//   this.handleNotificationClick(i18next.t(error.response.data.message), "error") :
				//     this.handleNotificationClick("Internal server error", "error")
			});

		axios({
			method: "post",
			url: baseURL + "/export_meal_vouchers",
			data: {
				startDate: start,
				endDate: end,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response) {
					this.setState({
						dataToDownload: response.data.downloadLink,
					});
				}
			})
			.catch((error) => {
				// if (error.response.data)
				//   error.response.data.message ?
				//   this.handleNotificationClick(i18next.t(error.response.data.message), "error") :
				//     this.handleNotificationClick("Internal server error", "error")
			});

		this.setState({
			loadedData: true,
		});
	}
	componentDidMount() {
		var date = new Date();
		var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
		var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
		setTimeout(() => {
			this.loadData(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), "all", "accepted");
		}, 300);
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};
	updateData(newFilterText, newItems) {
		this.setState({
			filterText: newFilterText,
			filteredItems: newItems,
		});
		if (newFilterText === "") this.state.filteredItems = this.state.rows;
	}
	render() {
		return (
			<div className="page-container">
				<NotificationSystem ref="notificationSystem" style={style} />
				<FiltersComponent
					voucher={true}
					startDate={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
					endDate={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)}
					status={"accepted"}
					loadData={this.loadData}
				></FiltersComponent>
				{this.state.loadedData && this.state.dataToDownload ? (
					<DataTable
						columns={this.state.columns}
						data={this.state.filteredItems}
						pagination
						keyField="Leave_Id"
						striped
						responsive
						subHeaderComponent={<SubHeaderNoExport allData={this.state.data} filterText={this.state.filterText} updateData={this.updateData} insertButton="hidden" dataToDownload={this.state.dataToDownload}></SubHeaderNoExport>}
						expandableRows
						expandableRowsComponent={<ExpandableComponent data={this.state.filteredItems} />}
						expandOnRowClicked
						subHeader
						noHeader
					/>
				) : (
					<LoadingComponent />
				)}
			</div>
		);
	}
}

export default MealVoucher;
