import React, { Component } from "react";
import AllRequestsSupervisor from "components/AllRequestsSupervisor/AllRequestsSupervisor";
class Requests extends Component {
  render() {
    return (
      <div className="content">
        <AllRequestsSupervisor></AllRequestsSupervisor>
      </div>
    );
  }
}

export default Requests;
