import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import i18next from 'i18next';
class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <nav className="pull-left">
            <ul>
              <li>
                <a href="https://hpm.ro/" target="_blank">{i18next.t('footer.about')}</a>
              </li>
              <li>
                <a href="https://hpm.ro/#contact" target="_blank">{i18next.t('footer.contact')}</a>
              </li>
            </ul>
          </nav>
          <p className="copyright pull-right">
            Copyright © 2020 HYPERMEDIA
            <br></br>   
            All rights reserved
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
