import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DateRangePicker from "components/RangeDatePicker/DateAndTime";
import cookie from "react-cookies";
import axios from "axios";
import { baseURL, MAX_INPUT_LENGTH, marriage, bereavement, maternity, paternity, maxAttachments, fileSize } from "constants.js";
import NotificationSystem from "react-notification-system";
import { style, warningMessage } from "variables/Variables.jsx";
import "bootstrap-daterangepicker/daterangepicker.css";
import AttachementDropzone from "components/Dropzone/AttachmentDropzone";
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import i18next from "i18next";
import { Ellipsis } from "react-awesome-spinners";
const formStyle = {
	backgroundColor: "#d3eef9",
	borderRadius: 5,
	padding: "10px",
	fontFamily: "Arial",
	paddingTop: "10%",
	paddingBottom: "10%",
};

class LeaveForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: "",
			_notificationSystem: null,
			dateRange: props.dateRange,
			initialSize: 3,
			message: "",
			type: "rest",
			events: [],
			requestAttachment: 0,
			attachments: [],
			loading: false
		};
		this.createLeave = this.createLeave.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.addAttachments = this.addAttachments.bind(this);
		this.computeEventLengthMarriage = this.computeEventLengthMarriage.bind(this);
		this.computeEventLength = this.computeEventLength.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.dateRange !== this.state.dateRange) {
			this.setState({ dateRange: nextProps.dateRange });
		}
	}
	createLeave() {
		let startDate = "",
			endDate = "";
		if (this.state.dateRange.length < 12) {
			startDate = this.state.dateRange;
			endDate = this.state.dateRange;
		} else {
			startDate = this.state.dateRange.split(" - ")[0];
			endDate = this.state.dateRange.split(" - ")[1];
		}
		let attachmentsToSend = [];
		for (var i = 0; i < this.state.attachments.length; i++) {
			let file = this.state.attachments[i];
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				let newFile = {
					file: {
						filename: file.name,
						content: reader.result,
					},
				};
				attachmentsToSend.push(newFile);
			};
			reader.onerror = function (error) {
				console.log("Error: ", error);
			};
		}
		let leave = {
			userID: cookie.load("userData").id,
			type: this.state.type,
			startDate: startDate,
			stopDate: endDate,
			reasonMessage: this.state.message,
			requestAttachment: this.state.requestAttachment,
			attachments: attachmentsToSend,
		};
		this.setState({ loading: true })
		let token = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/leaves",
			data: leave,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + token,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Leave created!", "success");
				this.setState({ loading: false })
				window.location.reload();
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
				this.setState({ loading: false })
			});
	}
	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};
	addAttachments(value) {
		if (value === "number") this.handleNotificationClick("Warrning! Upload at most " + maxAttachments + " files.", "warning");
		else if (value === "size") this.handleNotificationClick("Warrning! The files must be at most " + fileSize / 1000000 + "MB.", "warning");
		else if (value === "duplicate") this.handleNotificationClick("Warrning! File already inserted.", "warning");
		else
			this.setState({
				attachments: value,
			});
	}
	computeEventLengthMarriage(input, difference) {
		let val = moment(input).format("YYYY-MM-DD") + " - " + moment(input).format("YYYY-MM-DD");
		let startDate = moment(input);
		let days = marriage;
		for (var i = 0; i < days; i++) {
			let newDate = new Date(startDate).getTime() + i * 1000 * 60 * 60 * 24;
			newDate = moment(newDate);
			if (newDate.isoWeekday() === 6 || newDate.isoWeekday() === 7) days++;
		}
		var endDate = new Date(input).getTime() + 60 * 60 * 24 * 1000 * (days + difference);
		val = moment(input).format("YYYY-MM-DD") + " - " + moment(endDate).format("YYYY-MM-DD");
		this.setState({ dateRange: val });
		return { value: val, endDate: endDate };
	}
	computeEventLength(input, type, difference) {
		let val = moment(input).format("YYYY-MM-DD") + " - " + moment(input).format("YYYY-MM-DD");
		var daysOfLeave = 0;
		switch (type) {
			case "bereavement":
				daysOfLeave = bereavement;
				break;
			case "maternity":
				daysOfLeave = maternity;
				break;
			case "paternity":
				daysOfLeave = paternity;
				break;
			default:
				daysOfLeave = 1;
				break;
		}
		var endDate = new Date(input).getTime() + 60 * 60 * 24 * 1000 * (daysOfLeave + difference);
		val = moment(input).format("YYYY-MM-DD") + " - " + moment(endDate).format("YYYY-MM-DD");
		this.setState({ dateRange: val });
		return { value: val, endDate: endDate };
	}
	render() {
		return (
			<div className="content" style={{ minHeight: 560 }}>
				<Formik
					initialValues={{
						date: "",
						message: "",
						type: "rest",
						file: "",
						fileName: "Insert a file",
						startDate: moment().format("YYYY-MM-DD")
						// moment(new Date(moment()).getTime() + 60 * 60 * 24 * 1000).format("YYYY-MM-DD"),
					}}
					validate={(values) => {
						values.date = this.state.dateRange;
						const errors = {};
						if (!values.message > MAX_INPUT_LENGTH) {
							errors.message = "Message is too long";
						}
						if (!values.date) errors.date = "Range cannot be empty";
						values.file = this.state.file.name;
						values.fileName = this.state.file.name;
						this.setState({ message: values.message, type: values.type });
						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							this.createLeave();
							setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, setFieldValue, isSubmitting }) => (
						<Form style={formStyle} className="center-block">
							<div className="form-group col-md-12">
								<label htmlFor="type">{i18next.t("table_headers.type")}:</label>
								<select
									id="type"
									name="type"
									className="form-control"
									value={values.type}
									onChange={(e) => {
										setFieldValue("type", e.target.value);
										if (e.target.value === "marriage") {
											let result = this.computeEventLengthMarriage(values.startDate, -1);
											this.props.inputPickerChange(result.value, moment(values.startDate) + "," + moment(result.endDate), e.target.value);
										} else if (e.target.value === "rest" || e.target.value === "medical" || e.target.value === "unpaid") {
											this.props.inputPickerChange(
												moment(values.startDate).format("YYYY-MM-DD") + " - " + moment(values.startDate).format("YYYY-MM-DD"),
												moment(values.startDate) + "," + moment(values.startDate),
												e.target.value
											);
										} else if (e.target.value === "bereavement" || e.target.value === "maternity" || e.target.value === "paternity") {
											let result = this.computeEventLength(values.startDate, e.target.value, -1);
											this.props.inputPickerChange(result.value, moment(values.startDate) + "," + moment(result.endDate), e.target.value);
										}
										if (e.target.value === "medical" || e.target.value === "marriage") this.setState({ requestAttachment: 1 });
										else this.setState({ requestAttachment: 0 });
									}}
								>
									<option value="rest">{i18next.t("filter_component.rest")}</option>
									<option value="unpaid">{i18next.t("filter_component.unpaid")}</option>
									<option value="medical">{i18next.t("filter_component.medical")}</option>
									<option value="maternity">{i18next.t("filter_component.maternity")}</option>
									<option value="paternity">{i18next.t("filter_component.paternity")}</option>
									<option value="bereavement">{i18next.t("filter_component.bereavement")}</option>
									<option value="marriage">{i18next.t("filter_component.marriage")}</option>
								</select>
							</div>
							{(this.state.type === "rest" || this.state.type === "medical" || this.state.type === "unpaid") && (
								<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
									<label>{i18next.t("my_dashboard_page.holiday_period")}:</label>
									<DateRangePicker name="date" label={this.state.dateRange} onNewRange={this.props.inputPickerChange}></DateRangePicker>
									<ErrorMessage name="date" component="div" style={warningMessage} />
								</div>
							)}
							{(this.state.type === "maternity" || this.state.type === "paternity" || this.state.type === "marriage" || this.state.type === "bereavement") && (
								<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
									<label htmlFor="startDate">{i18next.t("start_date")}:</label>
									<DatetimeRangePicker
										onApply={(e, picker) => {
											setFieldValue("startDate", picker.startDate.format("YYYY-MM-DD"));
											if (values.type === "bereavement" || values.type === "paternity" || values.type === "maternity") {
												let result = this.computeEventLength(values.startDate, values.type, 0);
												this.props.inputPickerChange(result.value, picker.startDate + "," + moment(result.endDate));
											} else {
												let result = this.computeEventLengthMarriage(picker.startDate, 0);
												this.props.inputPickerChange(result.value, picker.startDate + "," + moment(result.endDate));
											}
										}}
										label={this.state.dateRange}
										initialSettings={{
											singleDatePicker: true,
											showDropdowns: true,
											startDate: moment().toDate(),
										}}
									>
										<input
											onChange={(e) => {
												console.log("");
											}}
											value={moment(this.state.dateRange.substring(0, 10)).format("YYYY-MM-DD")}
											type="text"
											name="startDate"
											className="form-control"
										/>
									</DatetimeRangePicker>
									<ErrorMessage name="startDate" component="div" style={warningMessage} />
								</div>
							)}
							<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
								<label>{i18next.t("dashboard_page.message")}:</label>
								<Field type="text" name="message" className="form-control" placeholder="Enter aditional message" />
								<ErrorMessage name="message" component="div" style={warningMessage} />
							</div>
							{this.state.requestAttachment ? (
								<div className="form-group center-block">
									<label style={{ paddingLeft: 15, paddingRight: 15 }}>{i18next.t("my_dashboard_page.Upload_file")}:</label>
									<AttachementDropzone addAttachments={this.addAttachments} style={{ paddingLeft: 15, paddingRight: 15 }}></AttachementDropzone>
								</div>
							) : (
									<div></div>
								)}

							{!this.state.loading ? <button className="btn btn-danger btn-fill center-block" type="submit" disabled={isSubmitting} style={{ marginTop: 10 }}>
								{i18next.t("my_dashboard_page.create")}
							</button> :
								<div style={{ textAlign: 'center' }}>
									<Ellipsis />
								</div>}
						</Form>
					)}
				</Formik>
				<NotificationSystem ref="notificationSystem" style={style} />
			</div>
		);
	}
}

export default LeaveForm;
