import React from "react";
import LoginForm from "components/LoginPage/LoginForm";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this)
  }
  login(email, password) {
    this.props.login(email, password)
  }

  render() {
    return (
      <div style={{
        backgroundSize: 'cover', height: '100vh',
        backgroundImage: `url("https://hpm.ro/wp-content/uploads/2016/06/hpm1-1-t1.jpg")`
      }}>
        <img className="pull-right" src="https://hpm.ro/wp-content/uploads/2016/06/hpm-logo.png"
          alg="login" style={{ width: 200, height: 45, marginRight: 20, marginTop: 20 }}
        />
        <div style={{ height: 60 }}></div>
        <LoginForm login={this.login}></LoginForm>
      </div >
    );
  }
}
export default Login;