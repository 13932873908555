import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth,
			layout: props.role,
		};
	}
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	}
	updateDimensions() {
		this.setState({ width: window.innerWidth });
	}
	componentDidMount() {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions.bind(this));
	}

	render() {
		const sidebarBackground = {
			backgroundImage: "url(" + this.props.image + ")",
		};

		return (
			<div id="sidebar" className="sidebar" data-color={this.props.color} data-image={this.props.image}>
				{this.props.hasImage ? <div className="sidebar-background" style={sidebarBackground} /> : null}
				<div className="logo">
					<a href="#" className="simple-text logo-normal">
						HyperHoliday
					</a>
				</div>
				<div className="sidebar-wrapper">
					<ul className="nav">
						{this.state.width <= 991 ? <AdminNavbarLinks /> : null}
						{this.props.routes.map((prop, key) => {
							if (!prop.redirect && prop.layout === this.state.layout)
								return (
									<li className={prop.upgrade ? "active active-pro" : this.activeRoute(prop.layout + prop.path)} key={key}>
										<NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active">
											<i className={prop.icon} />
											<p>{i18next.t("sidebar." + prop.name)}</p>
										</NavLink>
									</li>
								);
							return null;
						})}
					</ul>
				</div>
			</div>
		);
	}
}

export default Sidebar;
