import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { Row, Col, Grid } from "react-bootstrap";
import pdfFile from "../../assets/img/PDF_file_icon.png"
import { maxAttachments, fileSize } from "constants.js"
import i18next from 'i18next';
const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-block',
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    height: '30%',
    padding: 4,
    boxSizing: 'border-box'
};

const img = {
    objectFit: 'contain',
    width: '100%',
    height: 60
};
const baseStyle = {
    height: 'auto',
    alignItems: "center",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: 'white',
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
};
function AttachmentDropzone(props) {
    const [myFiles, setMyFiles] = useState([])
    const [previewFiles, setFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);

    const onDrop = acceptedFiles => {
        let filesTemp = [];
        const newFiles = [...filesToUpload]
        const previewFilesTemp = [...previewFiles]
        for (var i = 0; i < acceptedFiles.length; i++) {
            if (previewFilesTemp.length >= maxAttachments) {
                props.addAttachments("number");
                break;
            }
            if (acceptedFiles[i].size > fileSize)
                filesTemp = [...filesTemp, acceptedFiles[i]]
            else {
                if (newFiles.filter(e => e.path === acceptedFiles[i].path).length === 0) {
                    newFiles.push(acceptedFiles[i])
                    previewFilesTemp.push(Object.assign(
                        acceptedFiles[i], {
                        preview: URL.createObjectURL(acceptedFiles[i])
                    }));
                   
                }
                else{ props.addAttachments("duplicate");}
            }
        }
        if (filesTemp.length > 0)
            props.addAttachments("size");
        setFilesToUpload(newFiles)
        setFiles(previewFilesTemp)
        props.addAttachments(newFiles);
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*,application/pdf',
        onDrop,
    })

    const removeFile = file => () => {
        const newFiles = [...previewFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles)

        const newFilesToUpload = [...filesToUpload]
        newFilesToUpload.splice(newFilesToUpload.indexOf(file), 1)
        setFilesToUpload(newFilesToUpload)
        props.addAttachments(newFilesToUpload);
    }
    const files = myFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes{" "}
            <span onClick={removeFile(file)} className="glyphicon glyphicon-trash"></span>
        </li>
    ))

    const thumbs = previewFiles.map(file => (
        <div style={thumb} key={file.path}>
            <div>
                <Grid fluid>
                    <Row>
                        <Col xs={4}>
                            {file.type === "application/pdf" ?
                                <img src={pdfFile}
                                    style={img} alt="pdf placeholder"/>
                                :
                                <img src={file.preview}
                                    style={img} alt="png placeholder"/>}
                        </Col>
                        <Col xs={8} >
                            <Row>
                                <Col xs={10} > <div style={{ width: '100%', overflow: 'auto' }}>{file.name}</div></Col>
                                <Col xs={2} ><span onClick={removeFile(file)} className="glyphicon glyphicon-remove"></span></Col></Row>
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    ));

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    return (
        <section style={{ marginBottom: 20 }} className="container col-xs-12">
            <div style={baseStyle} {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p style={{
                    position: 'relative'
                    , WebkitTransform: 'translateY(10%)',
                    paddingLeft: 30, paddingRight: 30
                }} className="text-center">{i18next.t('my_dashboard_page.dropzone_message')}</p>

            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    )
}
export default AttachmentDropzone
