import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import CalendarAdminLeader from "components/Calendar/CalendarAdminLeader";
import RequestsCard from "components/RequestsCard/RequestsCard";
import { baseURL } from "constants.js";
import axios from "axios";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import ColorsLegend from "components/Calendar/ColorsLegend";
import i18next from "i18next";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { Button } from "react-bootstrap";
import CreateLeaveModal from "components/Modals/CreateLeaveModal";
class DashboardAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showDetails: false,
			index: 0,
			requests: [],
			_notificationSystem: null,
			loadedData: false,
		};
		this.hideModal = this.hideModal.bind(this);
		this.updateRequest = this.updateRequest.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.loadData = this.loadData.bind(this);
		setTimeout(() => {
			this.loadData();
		}, 200);
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	loadData() {
		let tokenValue = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/leaves_users",
			data: {
				type: "all",
				status: "pending",
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response && response.data) {
					let requests = [];
					for (let i = 0; i < response.data.length; i++) {
						requests.push(response.data[i]);
					}
					this.setState({
						requests: requests,
						loadedData: true,
					});
				}
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	hideModal() {
		this.setState({ showDetails: !this.state.showDetails });
	}
	updateRequest(id, status) {
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/leaves_status/" + this.state.requests[id].Id_leave,
			data: { status: status },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Leave updated", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	render() {
		return (
			<div className="page-container">
				<NotificationSystem ref="notificationSystem" style={style} />
        <CreateLeaveModal/>
				{this.state.loadedData ? (
					<Grid fluid>
						<Row>
							<Col md={8}>
								<Row>
									<h2 className="text-center">{i18next.t("dashboard_page.holidays_calendar")}</h2>
									<div>
										{/* <Button style={{ marginBottom: 10 }} className="btn btn-danger btn-fill">
											Add past leave
										</Button> */}
										{<CalendarAdminLeader />}
									</div>
								</Row>
								<Row style={{ marginTop: 10 }}>
									<ColorsLegend></ColorsLegend>
								</Row>
							</Col>
							<Col md={4}>
								<h2 className="text-center">{i18next.t("dashboard_page.pending_requests")}</h2>
								<div style={{ height: 600, overflow: "auto" }}>
									{this.state.requests.length > 0 ? (
										this.state.requests.map((c, index) => (
											<RequestsCard
												decline={this.updateRequest}
												accept={this.updateRequest}
												key={c.Id_leave}
												index={index}
												startDate={i18next.t("period") + ":" + c.startDate + ":" + c.stopDate}
												type={c.type}
												message={i18next.t("dashboard_page.message") + ":" + c.reasonMessage}
												name={c.firstName + " " + c.lastName}
											/>
										))
									) : (
										<div className="text-center bold">{i18next.t("dashboard_page.no_pending_requests")}</div>
									)}
								</div>
							</Col>
						</Row>
					</Grid>
				) : (
					<LoadingComponent />
				)}
			</div>
		);
	}
}

export default DashboardAdmin;
