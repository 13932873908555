import React, { Component } from "react";
import MyRequestsTable from "components/MyRequestsTable/MyRequestsTable.jsx";

class MyRequests extends Component {
  render() {
    return (
      <div className="content"  style={{ minHeight: 500 }}>
        <MyRequestsTable></MyRequestsTable>
      </div>
    );
  }
}

export default MyRequests;
