import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import cookie from "react-cookies";
import Moment from "moment";
import axios from "axios";
import { baseURL } from "constants.js";
import { Grid, Row, Modal, Button } from "react-bootstrap";
import avatarPhoto from "../../assets/img/default-avatar.png";
import NotificationSystem from "react-notification-system";
import { style, warningMessage } from "variables/Variables.jsx";
import i18next from "i18next";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import { Multiselect } from "multiselect-react-dropdown";

function ChangePasswordModal({ onAccept, ...props }) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("Change password")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						oldPassword: "",
						newPassword: "",
						confirmPassword: "",
					}}
					validate={(values) => {
						const errors = {};
						if (!values.oldPassword) {
							errors.oldPassword = i18next.t("profile_page.empty_password");
						} else if (values.oldPassword !== props.passwordoriginal) {
							errors.oldPassword = i18next.t("profile_page.different_password");
						}
						if (!values.newPassword) {
							errors.newPassword = i18next.t("profile_page.empty_password");
						} else if (!/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}/i.test(values.newPassword)) {
							errors.newPassword = i18next.t("profile_page.email_ragex");
						}
						if (!values.confirmPassword) {
							errors.confirmPassword = i18next.t("profile_page.empty_password");
						} else if (values.confirmPassword !== values.newPassword) {
							errors.confirmPassword = i18next.t("profile_page.different_password");
						}

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							onAccept(values);
							setSubmitting(false);
						}, 400);
					}}
				>
					{({ isSubmitting }) => (
						<Form className="center-block">
							<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
								<label>{i18next.t("profile_page.old_password")}:</label>
								<Field type="text" name="oldPassword" className="form-control" placeholder={i18next.t("profile_page.old_password_placeholder")} />
								<ErrorMessage name="oldPassword" component="div" style={warningMessage} />
							</div>
							<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
								<label>{i18next.t("profile_page.new_password")}:</label>
								<Field type="text" name="newPassword" className="form-control" placeholder={i18next.t("profile_page.new_password_placeholder")} />
								<ErrorMessage name="newPassword" component="div" style={warningMessage} />
							</div>
							<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
								<label>{i18next.t("profile_page.new_password_confirm")}:</label>
								<Field type="text" name="confirmPassword" className="form-control" placeholder={i18next.t("profile_page.confirm_password_placeholder")} />
								<ErrorMessage name="confirmPassword" component="div" style={warningMessage} />
							</div>
							<hr
								style={{
									marginTop: 20,
									color: "#e4e5e4",
									width: "100%",
								}}
							/>
							<Row>
								<Button onClick={props.onHide} style={{ marginRight: 10, float: "right" }}>
									{i18next.t("modal_buttons.close")}
								</Button>
								<Button type="submit" style={{ marginRight: 10, float: "right" }} disabled={isSubmitting}>
									{i18next.t("profile_page.update_password")}
								</Button>
							</Row>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
}
class MyProfile extends Component {
	constructor(props) {
		super(props);
		let userData = cookie.load("userData");
		let password = cookie.load("password");
		this.state = {
			_notificationSystem: null,
			changePasswordModalShow: false,
			file: avatarPhoto,
			userId: userData.id,
			firstNameOriginal: userData.firstName,
			lastNameOriginal: userData.lastName,
			emailOriginal: userData.email,
			passwordOriginal: password,
			departmentOriginal: userData.department,
			currentDaysLeftOriginal: userData.currentDaysLeft,
			lastYearDaysOriginal: userData.lastYearDays,
			roleOriginal: userData.userRole,
			supervisorName: "",
			supervisorOriginal: userData.supervisor,
			hireDateOriginal: Moment(userData.hireDate).format("MMMM Do, YYYY"),
			supervisors: "",
			departments: [],
			user: {
				password: "",
				firstName: userData.firstName,
				lastName: userData.lastName,
				email: userData.email,
				department: userData.department,
				userRole: userData.userRole,
				currentDaysLeft: userData.currentDaysLeft,
				lastYearDays: userData.lastYearDayss,
				supervisor: userData.supervisor,
			},
		};
		this.getSupervisor = this.getSupervisor.bind(this);
		this.getSupervisor();
		this.onSubmitButton = this.onSubmitButton.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.changePasswordModal = this.changePasswordModal.bind(this);
		this.hideModalAccept = this.hideModalAccept.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.createSupervisors = this.createSupervisors.bind(this);
		this.getDepartment = this.getDepartment.bind(this);
	}

	getSupervisor() {
		let tokenValue = cookie.load("token");
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		};
		// axios
		// 	.get(baseURL + "/users/" + this.state.supervisorOriginal, axiosConfig)
		// 	.then((response) => {
		// 		if (response && response.data) {
		// 			let name = response.data[0].firstName + " " + response.data[0].lastName;
		// 			this.setState({ supervisorName: name });
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		if (error.response.data !== undefined) {
		// 			if (error.response.data.message.includes("user_not_found")) this.handleNotificationClick("This user does not have a supervisor", "error");
		// 			else this.handleNotificationClick(i18next.t(error.response.data.message), "error");
		// 		}
		// 	});

		axios
			.get(baseURL + "/supervisors", axiosConfig)
			.then((response) => {
				if (response && response.data) {
					this.setState({ supervisors: response.data });
				}
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});

		axios
			.get(baseURL + "/departments", axiosConfig)
			.then((response) => {
				if (response && response.data) {
					this.setState({
						departments: response.data,
					});
				}
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	createSupervisors() {
		return this.state.supervisorOriginal
			.map((id) => this.state.supervisors.find((x) => x.id === id))
			.map((x) => {
				return { id: x.id, firstName: x.firstName + " " + x.lastName };
			});
	}

	changePasswordModal() {
		this.setState({ changePasswordModalShow: !this.state.changePasswordModalShow });
	}
	onSubmitButton(e) {
		e.preventDefault();
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/users/" + this.state.userId,
			data: this.state.user,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Profile updated!", "success");
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
		let userData = cookie.load("userData");
		userData.firstName = this.state.user.firstName;
		userData.lastName = this.state.user.lastName;
		userData.email = this.state.user.email;
		cookie.save("userData", userData);
	}
	handleChange(event) {
		this.setState({
			file: URL.createObjectURL(event.target.files[0]),
		});
	}

	hideModalAccept(values) {
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/users/" + this.state.userId,
			data: { password: values.confirmPassword },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Password updated!", "success");
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
		this.setState({ changePasswordModalShow: !this.state.changePasswordModalShow, validationsOk: false });
	}

	handleNotificationClick = (msg, level) => {
		this.state._notificationSystem = this.refs.notificationSystem;
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	getDepartment = () => {
		let department = this.state.departments.find((department) => department.id === parseInt(this.state.departmentOriginal));
		if (department) return department.name;
		else return "None";
	};
	render() {
		return cookie.load("userData") && this.state.user && this.state.supervisors && this.state.departments ? (
			<div className="content">
				<Grid fluid>
					<NotificationSystem ref="notificationSystem" style={style} />
					<Row>
						<Formik
							initialValues={{
								firstName: this.state.firstNameOriginal,
								lastName: this.state.lastNameOriginal,
								email: this.state.emailOriginal,
								supervisor: this.state.supervisorOriginal ? this.createSupervisors() : "",
							}}
							validate={(values) => {
								const errors = {};
								if (!values.email) {
									values.email = this.state.emailOriginal;
								} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
									errors.email = i18next.t("employee_form.invalid_email");
								}
								if (!values.firstName) {
									values.firstName = this.state.firstNameOriginal;
								}
								if (!values.lastName) {
									values.lastName = this.state.lastNameOriginal;
								}
								let user = {
									firstName: values.firstName,
									lastName: values.lastName,
									email: values.email,
									department: values.department,
									userRole: values.userRole,
									currentDaysLeft: values.currentDaysLeft,
									lastYearDays: values.lastYearDayss,
								};
								this.setState({ user: user });

								return errors;
							}}
						>
							{({ values, isSubmitting }) => (
								<Form style={{ width: "80%", marginTop: 50 }} className="center-block">
									<div>
										<div className="form-group col-md-6">
											<label htmlFor="firstName">{i18next.t("table_headers.first_name")}:</label>
											<Field type="name" className="form-control" name="firstName" value={values.firstName} />
											<ErrorMessage name="firstName" component="div" style={warningMessage} />
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="lastName">{i18next.t("table_headers.last_name")}:</label>
											<Field type="name" name="lastName" className="form-control" value={values.lastName} />
											<ErrorMessage name="lastName" component="div" style={warningMessage} />
										</div>
									</div>
									<div>
										<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group col-md-6">
											<label>{i18next.t("table_headers.email")}:</label>
											<Field readOnly type="email" name="email" className="form-control" value={values.email} />
											<ErrorMessage name="email" component="div" style={warningMessage} />
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="department">{i18next.t("employee_form.department")}:</label>
											<Field readOnly id="department" name="department" className="form-control" value={this.getDepartment()} />
										</div>
									</div>
									<div>
										<div className="form-group col-md-6">
											<label htmlFor="role">{i18next.t("employee_form.role")}:</label>
											<Field readOnly id="role" name="role" className="form-control" value={this.state.roleOriginal} />
										</div>
										<div className="form-group col-md-6">
											<label htmlFor="supervizor">{i18next.t("employee_form.supervisor")}:</label>
											{values.supervisor.length ? (
												<Multiselect id="supervisor" name="supervisor" className="form-control" selectedValues={values.supervisor} disable hidePlaceholder displayValue="firstName"></Multiselect>
											) : (
												<Field readOnly className="form-control" value={"None"} />
											)}
										</div>
									</div>
									<div>
										<div className="form-group col-md-6">
											<label>{i18next.t("employee_form.this_year_days")}:</label>
											<Field value={this.state.currentDaysLeftOriginal} readOnly type="name" name="currentDaysLeft" className="form-control" />
											<ErrorMessage name="currentDaysLeft" component="div" style={warningMessage} />
										</div>
										<div className="form-group col-md-6">
											<label>{i18next.t("employee_form.last_year_days")}:</label>
											<Field value={this.state.lastYearDaysOriginal} readOnly type="name" name="lastYearDays" className="form-control" />
											<ErrorMessage name="lastYearDays" component="div" style={warningMessage} />
										</div>
									</div>
									<button className="btn btn-danger btn-fill center-block" type="submit" disabled={isSubmitting} onClick={this.onSubmitButton} style={{ marginTop: 10 }}>
										{i18next.t("profile_page.update_profile")}
									</button>
									<p className="text-center text-muted " onClick={this.changePasswordModal} style={{ marginTop: 10 }}>
										{i18next.t("profile_page.change_password")}{" "}
										<strong style={{ cursor: "pointer" }}>
											<em>{i18next.t("profile_page.here")}</em>
										</strong>
									</p>
								</Form>
							)}
						</Formik>
						<ChangePasswordModal onAccept={this.hideModalAccept} passwordoriginal={this.state.passwordOriginal} show={this.state.changePasswordModalShow} onHide={this.changePasswordModal} />
					</Row>
				</Grid>
			</div>
		) : (
			<LoadingComponent />
		);
	}
}

export default MyProfile;
