
import React, { Component } from "react";
import { colors } from 'constants.js';
import i18next from 'i18next';
const holiday=i18next.t('my_dashboard_page.holiday')
const paternity=i18next.t('filter_component.paternity')
const unpaid=i18next.t('filter_component.unpaid')
const bereavement=i18next.t('filter_component.bereavement')
const maternity=i18next.t('filter_component.maternity')
const medical=i18next.t('filter_component.medical')
const rest=i18next.t('filter_component.rest')
const marriage=i18next.t('filter_component.marriage')
const pending=i18next.t('filter_component.pending')
class ColorsLegend extends Component {

    constructor(props){
        super(props)
        this.state = {
            removePending:props.removePending
          }
          this.createLegend=this.createLegend.bind(this)
    }

    createLegend() {
        var legend = [];
        var type = "fa fa-circle text-";
        legend.push(<i className={type} key={0} style={{ color: colors[0] }} />);
        legend.push(" "+holiday);
        legend.push(<i className={type} key={4} style={{ color: colors[4] }} />);
        legend.push(" "+paternity);
        legend.push(<i className={type} key={2} style={{ color: colors[2] }} />);
        legend.push(" "+unpaid);
        legend.push(<i className={type} key={5} style={{ color: colors[5] }} />);
        legend.push(" "+bereavement);
        legend.push(<i className={type} key={3} style={{ color: colors[3] }} />);
        legend.push("  "+maternity);
        legend.push(<i className={type} key={1} style={{ color: colors[1] }} />);
        legend.push(" "+medical);
        legend.push(<i className={type} key={6} style={{ color: colors[6] }} />);
        legend.push(" "+rest);
        legend.push(<i className={type} key={7} style={{ color: colors[7] }} />);
        legend.push(" "+marriage);
        !this.state.removePending && legend.push(<i className={type} key={8} style={{ color: colors[8] }} />);
        !this.state.removePending && legend.push(" "+pending);
        return legend;
    }

    render() {
        
        return (
            <div className="content">
                <div className="legend text-center">{this.createLegend()}</div>
            </div>
        );
    }
}

export default ColorsLegend;