import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/light-bootstrap-dashboard-react.css?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import Login from "views/Login";
import RedirectDashboard from "layouts/RedirectDashboard";
import axios from "axios";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import i18next from "i18next";
import { baseURL } from "constants.js";
class HomePage extends React.Component {
	constructor(props) {
		super(props);
		if (cookie.load("userData")) {
			if (cookie.load("userData").isManager === 1)
				this.state = {
					userData: {
						role: "supervisor",
					},
				};
			else if (cookie.load("userData").userRole === "employee")
				this.state = {
					userData: {
						_notificationSystem: null,
						role: "employee",
					},
				};
			else
				this.state = {
					userData: {
						_notificationSystem: null,
						role: "admin",
					},
				};
		} else {
			this.state = {
				userData: {
					_notificationSystem: null,
					role: "default",
				},
			};
		}

		this.login = this.login.bind(this);
		this.login = this.login.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}
	login(email, pass) {
		if (email && pass) {
			axios({
				method: "post",
				url: baseURL + "/login",
				data: {
					email: email,
					password: pass,
				},
				headers: {
					"Content-Type": "application/json",
				},
			}).then(
				(response) => {
					if (response.data.success === true) {
						if (response.data.user.isManager === 1)
							this.setState((prevState) => ({
								userData: { ...prevState.userData, role: "supervisor" },
							}));
						else if (response.data.user.userRole === "employee")
							this.setState((prevState) => ({
								userData: { ...prevState.userData, role: "employee" },
							}));
						else
							this.setState((prevState) => ({
								userData: { ...prevState.userData, role: "admin" },
							}));
						let expirationDate = new Date();
						expirationDate.setDate(expirationDate.getDate() + 1);
						cookie.save("userData", JSON.stringify(response.data.user), expirationDate.toUTCString());
						let tokenValue = response.data.token;
						cookie.save("token", tokenValue, expirationDate.toUTCString());
						cookie.save("password", response.data.password, expirationDate.toUTCString());
					}
				},
				(error) => {
					if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
				}
			);
		}
	}
	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};
	render() {
		let pageContent =
			this.state.userData.role === "admin" ? (
				<RedirectDashboard role={this.state.userData.role} />
			) : this.state.userData.role === "employee" ? (
				<RedirectDashboard role={this.state.userData.role} />
			) : this.state.userData.role === "supervisor" ? (
				<RedirectDashboard role={this.state.userData.role} />
			) : (
				<Login login={this.login} />
			);

		return (
			// <Interceptor login={this.login}>
			<div className="app-wrapper">
				<NotificationSystem className="alert alert-success" ref="notificationSystem" style={style} />
				<div className="page-content-wrapper">{pageContent}</div>
			</div>
			// </Interceptor>
		);
	}
}

export default HomePage;
ReactDOM.render(
	<div>
		<HomePage></HomePage>
	</div>,
	document.getElementById("root")
);
