import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import "../../assets/css/Employees.css";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import DataTable from "react-data-table-component";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import i18next from "i18next";
import StatusComponenet from "components/StatusLabelComponent/StatusComponent";
import SubHeaderNoExport from "components/SubHeaderTable/SubHeaderNoExport";
import AddUserModal from "../../components/Modals/AddUserModal"
import UpdateUserModal from "../../components/Modals/UpdateUserModal"
import DeleteUserModal from "../../components/Modals/DeleteUserModal"
const columns = [{ name: i18next.t("table_headers.last_name"), selector: "lastName" },
{ name: i18next.t("table_headers.first_name"), selector: "firstName" },
{ name: i18next.t("table_headers.email"), selector: "email", grow: 2 },
{ name: i18next.t("table_headers.status"), selector: "userStatus", grow: 1.2 },
{ name: i18next.t("active_employees.days_left"), selector: "currentDaysLeft" },
{ name: i18next.t("table_headers.actions"), selector: "actions" }]
class EmployeesPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			addUserModalShow: false,
			updateUserModalShow: false,
			deleteUserModalShow: false,
			userToDelete: "",
			userToUpdate: "",
			_notificationSystem: null,
			rows: [],
			filterText: "",
			filteredItems: [],
			loadedData: false,
			supervisors:[],
			departments:[]
		};

		this.setAddUserModalShow = this.setAddUserModalShow.bind(this);
		this.setUpdateUserModalShow = this.setUpdateUserModalShow.bind(this);
		this.setDeleteUserModalShow = this.setDeleteUserModalShow.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.updateData = this.updateData.bind(this);
	}

	setAddUserModalShow() {
		this.setState({ addUserModalShow: !this.state.addUserModalShow });
	}

	setUpdateUserModalShow() {
		this.setState({ updateUserModalShow: !this.state.updateUserModalShow });
	}

	setDeleteUserModalShow() {
		this.setState({ deleteUserModalShow: !this.state.deleteUserModalShow });
	}

	updateData(newFilterText, newItems) {
		this.setState({
			filterText: newFilterText,
			filteredItems: newItems,
		});
		if (newFilterText === "") this.state.filteredItems = this.state.rows;
	}

	componentDidMount() {
		let tokenValue = cookie.load("token");
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		};

		axios
			.get(baseURL + "/users", axiosConfig)
			.then((response) => {
				if (response && response.data) {
					let resData = this.state;
					resData.rows = response.data;
					for (let i = 0; i < resData.rows.length; i++) {
						resData.rows[i]["actions"] = this.buildUsersTableActions(i);
						resData.rows[i]["userStatus"] = <StatusComponenet status={resData.rows[i].userStatus}></StatusComponenet>;
					}
					this.setState({
						data: resData,
						filteredItems: this.state.rows,
						loadedData: true,
					});
				}
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
			axios.get(baseURL + '/supervisors', axiosConfig)
			  .then(response => {
				if (response && response.data) {
				  this.setState({ supervisors: response.data });
				}
			  })
			  .catch(error => {
				if (error.response !== undefined)
				  this.handleNotificationClick(i18next.t(error.response.data.message), "error")
			  });

			  axios
			.get(baseURL + "/departments", axiosConfig)
			.then((response) => {
				if (response && response.data) {
					this.setState({
						departments: response.data,
					});
				}
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
			
	}

	buildUsersTableActions(index) {
		return (
			<div>
				<i className="fas fa-user-alt-slash" aria-hidden="true" onClick={() => this.verifyDeleteUser(index)}></i>
				<i style={{ marginLeft: 20 }} className="fa fa-edit" onClick={() => this.getToUpdateUser(index)}></i>
			</div>
		);
	}

	verifyDeleteUser = (index) => {
		this.setState({
			userToDelete: this.state.rows[index].id,
		});
		this.setDeleteUserModalShow();
	};

	onDelete() {
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/users_del/" + this.state.userToDelete,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Deleted successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	getToUpdateUser = (index) => {
		this.setState({
			userToUpdate: this.state.rows[index],
		});
		this.setUpdateUserModalShow();
	};

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		return (
			this.state.loadedData && this.state.supervisors && this.state.departments ? 
			<div className="mainDivStyle">
				<NotificationSystem ref="notificationSystem" style={style} />
				<AddUserModal departments={this.state.departments} supervisors={this.state.supervisors} show={this.state.addUserModalShow} onHide={this.setAddUserModalShow} />
				<UpdateUserModal departments={this.state.departments} supervisors={this.state.supervisors} user={this.state.userToUpdate} show={this.state.updateUserModalShow} onHide={this.setUpdateUserModalShow} />
				<DeleteUserModal onDelete={this.onDelete} show={this.state.deleteUserModalShow} onHide={this.setDeleteUserModalShow} />
				
					<DataTable
						class="employee_table"
						columns={columns}
						data={this.state.filteredItems}
						pagination
						keyField="Leave_Id"
						striped
						responsive
						subHeaderComponent={<SubHeaderNoExport allData={this.state.rows} filterText={this.state.filterText} updateData={this.updateData} insertButton="visible" setAddUserModalShow={this.setAddUserModalShow}></SubHeaderNoExport>}
						subHeader
						noHeader
					/>
			</div>: <LoadingComponent />
		)
	}
}

export default EmployeesPage;
