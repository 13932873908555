import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import { Modal } from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import DataTable from "react-data-table-component";
import FiltersComponent from "../Admin/FiltersComponent";
import moment from "moment";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import i18next from "i18next";
import StatusComponenet from "components/StatusLabelComponent/StatusComponent";
import SubHeaderExport from "components/SubHeaderTable/SubHeaderExport";

function CancelLeaveModal({ onCancelLeave, ...props }) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("CANCEL LEAVE")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3 id="deleteBody">{i18next.t("leave_management.cancel_request_check")}</h3>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton onClick={onCancelLeave}> {i18next.t("modal_buttons.yes")}</CustomButton>
				<CustomButton onClick={props.onHide}>{i18next.t("modal_buttons.close")}</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}
class AllRequestsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			cancelLeaveModalShow: false,

			requestIndexToCancel: -1,
			requestToCancel: "",
			status: "canceled",

			requestIndexToDownload: -1,
			requestToDownload: "",

			columns: [
				{
					name: i18next.t("table_headers.last_name"),
					selector: "lastName",
					sortable: true,
				},
				{
					name: i18next.t("table_headers.first_name"),
					selector: "firstName",
					sortable: true,
				},
				{
					name: i18next.t("table_headers.type"),
					selector: "type",
				},
				{
					name: i18next.t("table_headers.start_date"),
					selector: "startDate",
				},
				{
					name: i18next.t("table_headers.end_date"),
					selector: "stopDate",
				},
				{
					name: i18next.t("table_headers.status"),
					selector: "status",
					grow: 1.2,
				},
				{
					name: i18next.t("table_headers.additional_message"),
					selector: "reasonMessage",
					grow: 2,
				},
				{
					name: i18next.t("table_headers.attachments"),
					selector: "attachments",
				},
				{
					name: i18next.t("table_headers.working_days"),
					selector: "workingDays",
				},
				{
					name: i18next.t("table_headers.actions"),
					selector: "actions",
				},
			],
			rows: [],
			filterText: "",
			filteredItems: [],
			downloadLink: "",
			loadedData: false,
		};

		this.setCancelLeaveModalShow = this.setCancelLeaveModalShow.bind(this);
		this.onCancelLeave = this.onCancelLeave.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.loadData = this.loadData.bind(this);
		this.setAttachments = this.setAttachments.bind(this);
		this.updateData = this.updateData.bind(this);
	}
	setCancelLeaveModalShow() {
		this.setState({ cancelLeaveModalShow: !this.state.cancelLeaveModalShow });
	}
	loadData(start, end, type, status) {
		let tokenValue = cookie.load("token");

		axios({
			method: "post",
			url: baseURL + "/leaves_users",
			data: {
				type: type,
				startDate: start,
				endDate: end,
				status: status,
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response && response.data) {
					let resData = this.state;
					resData.rows = [];
					for (let i = 0; i < response.data.length; i++) {
						let tempAttachments = [];
						if (response.data[i].type === "medical" || response.data[i].type === "marriage") {
							for (let j = 0; j < response.data[i]["attachments"].length; j++) {
								tempAttachments.push({ fileName: response.data[i]["attachments"][j].fileName, downloadLink: response.data[i]["attachments"][j].link });
							}
						}
						let newRow = {
							firstName: response.data[i].firstName,
							lastName: response.data[i].lastName,
							startDate: response.data[i].startDate,
							stopDate: response.data[i].stopDate,
							type: i18next.t(`filter_component.${response.data[i].type}`),
							reasonMessage: response.data[i].reasonMessage,
							workingDays: response.data[i].workingDays,
							Id_leave: response.data[i].Id_leave,
							status: <StatusComponenet status={response.data[i].status}></StatusComponenet>,
							statusDownload:response.data[i].status,
							actions: this.buildRequestsTableActions(i, response.data[i].Id_leave, response.data[i].status),
							attachments: this.setAttachments(tempAttachments),
						};
						resData.rows.push(newRow);
					}
					this.setState({
						data: resData.rows,
						filteredItems: this.state.rows,
						loadedData: true,
						filterText: '',
					});
				}
			})
			.catch((error) => {
				if (error.response === undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}
	componentDidMount() {
		setTimeout(() => {
			this.loadData( moment().format("YYYY-MM-01"), moment().format("YYYY-MM-") +moment().daysInMonth(), "all", "all");
		}, 300);
	}

	setAttachments(data) {
		if (data.length > 0)
			return data.map((c, index) => (
				<a key={c.fileName} href={c.downloadLink} target="_blank" rel="noopener noreferrer">
					{c.fileName}
					<br></br>
				</a>
			));
		else return <a href="#" style={{ color: "black" }}></a>;
	}

	buildRequestsTableActions(index, id, status) {
		return (
			<div>
				{status === "pending" || status === "accepted"? <i className="fas fa-window-close" aria-hidden="true" onClick={() => this.verifyCancelRequest(index)}></i> : null}
				<i onClick={() => this.getToDownloadRequest(index, id)} style={{ marginLeft: 20 }} className="fas fa-file-download">
					{" "}
				</i>
			</div>
		);
	}
	verifyCancelRequest = (index) => {
		this.setState({
			requestIndexToCancel: index,
		});
		let requestId = this.state.rows[index].Id_leave;
		this.setState({
			requestToCancel: requestId,
		});
		this.setCancelLeaveModalShow();
	};
	onCancelLeave() {
		const leave = {
			status: this.state.status,
		};
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/leaves_status/" + this.state.requestToCancel,
			data: leave,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Canceled successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	getToDownloadRequest = (index, id) => {
		this.setState({
			requestIndexToDownload: index,
		});
		let request = this.state.rows[index];
		this.setState({
			requestToDownload: request,
		});
		let tokenValue = cookie.load("token");
		axios({
			method: "get",
			url: baseURL + "/export_as_pdf/" + id,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		}).then((response) => {
			window.open(response.data.downloadLink);
		});
	};

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	updateData(newFilterText, newItems) {
		this.setState({
			filterText: newFilterText,
			filteredItems: newItems,
		});
		if (newFilterText === "") this.state.filteredItems = this.state.rows;
	}

	render() {
		return (
			<div className="page-container">
				<NotificationSystem ref="notificationSystem" style={style} />
				<CancelLeaveModal onCancelLeave={this.onCancelLeave} show={this.state.cancelLeaveModalShow} onHide={this.setCancelLeaveModalShow} />
				<FiltersComponent voucher={false} loadData={this.loadData} status={"all"}></FiltersComponent>
				{this.state.loadedData ? (
					<DataTable
						columns={this.state.columns}
						data={this.state.filteredItems}
						pagination
						keyField="Leave_Id"
						striped
						responsive
						subHeaderComponent={<SubHeaderExport data={this.state.data} allData={this.state.rows} filterText={this.state.filterText} updateData={this.updateData}></SubHeaderExport>}
						subHeader
						noHeader
					/>
				) : <LoadingComponent />}
			</div>
		);
	}
}

export default AllRequestsTable;
