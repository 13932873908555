import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import CalendarEmployee from "components/Calendar/CalendarEmployee";
import DaysCard from "components/DaysCard/DaysCard";
import cookie from "react-cookies";
import axios from "axios";
import { baseURL, map } from "constants.js";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import "bootstrap-daterangepicker/daterangepicker.css";
import ColorsLegend from "components/Calendar/ColorsLegend";
import i18next from "i18next";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import LeaveForm from "components/CreateLeaveForm/LeaveForm";
import moment from "moment";

const day = 60 * 60 * 24 * 1000;
class DashboardEmployee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			dateReange: moment().format("YYYY-MM-DD") + " - " + moment().format("YYYY-MM-DD"),
			//  moment(new Date(moment()).getTime() + day).format("YYYY-MM-DD") + " - " + moment(new Date(moment()).getTime() + day).format("YYYY-MM-DD"),
			initialSize: 0,
			message: "",
			events: [],
			currentDays: 0,
			lastYearDays: 0,
			totalDays: 0,
			loadedData: false,
		};
		this.handleLanguage = this.handleLanguage.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.insertEvent = this.insertEvent.bind(this);
		this.getData = this.getData.bind(this);
		setTimeout(() => {
			this.getData();
		}, 100);
	}

	getData() {
		let tokenValue = cookie.load("token");
		let user = cookie.load("userData");
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		};
		axios({
			method: "post",
			url: baseURL + "/company_holidays",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response && response.data) {
					let events = this.state.events;
					for (let i = 0; i < response.data.length; i++) {
						let start = new Date(response.data[i].startDate);
						let end = new Date(response.data[i].stopDate);
						events.push({
							title: response.data[i].name,
							startDate: start,
							endDate: end,
							hexColor: map["holiday"],
						});
					}
					this.setState({
						events: events,
						loadedData: true,
					});
				}
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});

		axios
			.get(baseURL + "/accepted_leaves/" + user.id, axiosConfig)
			.then((response) => {
				if (response && response.data) {
					let events = this.state.events;
					for (let i = 0; i < response.data.length; i++) {
						let start = new Date(response.data[i].startDate);
						let end = new Date(response.data[i].stopDate);
						events.push({
							title: response.data[i].type.toUpperCase(),
							startDate: start,
							endDate: end,
							hexColor: map[response.data[i].type],
						});
					}
					// events.push({
					// 	title: "New holiday",
					// 	startDate: new Date(new Date(moment()).getTime() + 1000 * 60 * 60 * 24),
					// 	endDate: new Date(new Date(moment()).getTime() + 1000 * 60 * 60 * 24),
					// 	hexColor: "red",
					// });
					this.setState({
						events: events,
						initialSize: events.length,
					});
				}
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});

		axios
			.get(baseURL + "/leaves_user/" + user.id, axiosConfig)
			.then((response) => {
				if (response && response.data) {
					let events = this.state.events;
					for (let i = 0; i < response.data.length; i++) {
						if (response.data[i].status === "pending") {
							let start = new Date(response.data[i].startDate);
							let end = new Date(response.data[i].stopDate);
							events.push({
								title: response.data[i].type.toUpperCase(),
								startDate: start,
								endDate: end,
								hexColor: map["pending"],
							});
						}
					}
					events.push({
						title: "New holiday",
						startDate: new Date(new Date(moment())),
						// new Date(new Date(moment()).getTime() + 1000 * 60 * 60 * 24),
						endDate: new Date(new Date(moment())),
						// new Date(new Date(moment()).getTime() + 1000 * 60 * 60 * 24),
						hexColor: "red",
					});
					this.setState({
						events: events,
						initialSize: events.length,
					});
				}
			})
			.catch((error) => {
				this.handleNotificationClick("Error", "error");
			});

		axios
			.get(baseURL + "/get_cards/" + user.id, axiosConfig)
			.then((response) => {
				if (response && response.data) {
					this.setState({
						currentDays: response.data.currentDays,
						lastYearDays: response.data.lastYearDays,
						totalDays: response.data.totalDays,
					});
				}
			})
			.catch((error) => {
				if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleLanguage = (langValue, newEv) => {
		var today = new Date();
		let start = newEv.startDate;
		if (moment(start).diff(moment(), "days") < 0) this.handleNotificationClick("Warning! Start date can not be before today! Pick a different date range.", "error");
		else {
			this.insertEvent(newEv);
			this.setState({
				dateReange: langValue,
			});
		}
	};

	inputPickerChange = (label, eventDates, type) => {
		if (label.length < 12) label = label + " - " + label;
		var today = new Date();
		let start = new Date(Number(eventDates.split(",")[0]));
		if (moment(start).diff(moment(), "days") < 0) this.handleNotificationClick("Warning! Start date can not be before today! Pick a different date range.", "error");
		else {
			let newEv = {
				title: "New holiday",
				startDate: new Date(Number(eventDates.split(",")[0])),
				endDate: new Date(Number(eventDates.split(",")[1])),
				hexColor: "red",
			};
			let isOvelapping = this.insertEvent(newEv, type);
			this.setState({
				dateReange: label,
			});
			return isOvelapping;
		}
	};
	insertEvent(newEv, type) {
		let ok = true;
		if (type !== "marriage" && type !== "maternity" && type !== "paternity" && type !== "bereavement")
			for (let i = 0; i < this.state.events.length - 1; i++) {
				if (newEv.startDate.getTime() >= this.state.events[i].startDate.getTime() && newEv.endDate.getTime() <= this.state.events[i].endDate.getTime() && this.state.events[i].hexColor !== "#ef4446") {
					ok = false;
				} else if (newEv.startDate.getTime() <= this.state.events[i].endDate.getTime() && newEv.endDate.getTime() >= this.state.events[i].endDate.getTime() && this.state.events[i].hexColor !== "#ef4446") {
					ok = false;
				} else if (newEv.startDate.getTime() <= this.state.events[i].startDate.getTime() && newEv.endDate.getTime() >= this.state.events[i].startDate.getTime() && this.state.events[i].hexColor !== "#ef4446") {
					ok = false;
				}
			}
		if (ok === false) {
			this.handleNotificationClick("Leaves are overlapping", "error");
			return true;
		} else {
			let events = this.state.events;
			var filtered = events.filter(function (el) {
				return el.title !== "New holiday";
			});
			this.setState((previousState) => ({
				events: [...filtered, newEv],
			}));
			return false;
		}
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		return (
			<div className="page-container">
				{this.state.loadedData ? (
					<Grid fluid>
						<Row>
							<Col md={4}>
								<DaysCard days={this.state.currentDays} typeDays={i18next.t("my_dashboard_page.days_left_current_year")}></DaysCard>
							</Col>
							<Col md={4}>
								<DaysCard days={this.state.lastYearDays} typeDays={i18next.t("my_dashboard_page.days_left_last_year")}></DaysCard>
							</Col>
							<Col md={4}>{/* <DaysCard days={this.state.totalDays} typeDays={i18next.t("my_dashboard_page.total_days")}></DaysCard> */}</Col>
						</Row>
						<Row>
							<Col md={8}>
								<div>
									<h2 className="text-center">{i18next.t("my_dashboard_page.my_holidays_calendar")}</h2>
									<CalendarEmployee events={this.state.events} onSelectLanguage={this.handleLanguage.bind(this)} />
									<Row style={{ marginTop: 10 }}>
										<ColorsLegend></ColorsLegend>
									</Row>
								</div>
							</Col>
							<Col md={4}>
								<h2 className="text-center">{i18next.t("my_dashboard_page.create_request")}</h2>
								<LeaveForm dateRange={this.state.dateReange} inputPickerChange={this.inputPickerChange}></LeaveForm>
							</Col>
						</Row>
					</Grid>
				) : (
					<LoadingComponent />
				)}
				<NotificationSystem ref="notificationSystem" style={style} />
			</div>
		);
	}
}

export default DashboardEmployee;
