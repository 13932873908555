import React from "react";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import cookie from "react-cookies";
import Axios from "axios";
import { baseURL } from "constants.js";
import NotificationSystem from "react-notification-system";
import { style, warningMessage } from "variables/Variables.jsx";
import i18next from "i18next";

function validateEmail(value) {
	let error;
	if (!value) {
		error = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
		error = "Invalid email address";
	}
	return error;
}
const passwordRevealed = { position: "absolute", left: "auto", right: 20, textIndent: 32, top: 13 };
function ForgotPasswordModal({ onAccept, ...props }) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" style={{ paddingTop: 100 }}>
			<Modal.Header style={{ marginLeft: 15, marginRight: 15 }} closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("login_page.forgot_password")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						email: "",
					}}
					validate={(values) => {
						const errors = {};
						if (!values.email) {
							errors.email = i18next.t("employee_form.email_required");
						}
						return errors;
					}}
					onSubmit={({ values, setSubmitting }) => {
						setTimeout(() => {
							alert(values);
							setSubmitting(false);
						}, 400);
					}}
				>
					{({ errors, validateForm, values, isSubmitting }) => (
						<Form className="center-block">
							<div style={{ paddingLeft: 15, paddingRight: 15 }} className="form-group center-block">
								<label>{i18next.t("table_headers.email")}:</label>
								<Field type="text" name="email" className="form-control" placeholder={i18next.t("employee_form.email_placeholder")} validate={validateEmail} />
								{errors.email && <div style={warningMessage}>{errors.email}</div>}
							</div>
							<hr
								style={{
									marginTop: 20,
									color: "#e4e5e4",
									width: "100%",
								}}
							/>
							<Row>
								<Col md={6}></Col>
								<Col md={6}>
									<Button style={{ float: "right" }} onClick={props.onHide}>
										{i18next.t("modal_buttons.close")}
									</Button>
									<Button
										onClick={() => {
											validateForm().then(() => onAccept(values.email));
										}}
										style={{ marginRight: 10, float: "right" }}
										disabled={isSubmitting}
									>
										{i18next.t("login_page.send_email")}
									</Button>
								</Col>
							</Row>
						</Form>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
}
class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			changePasswordModalShow: false,
			showComponent: false,
			isPasswordRevealed: false,
			email: "",
			password: "",
			_notificationSystem: null,
		};

		this.togglePassword = this.togglePassword.bind(this);
		this.changePasswordModal = this.changePasswordModal.bind(this);
		this.hideModalAccept = this.hideModalAccept.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}

	togglePassword() {
		this.setState({
			isPasswordRevealed: !this.state.isPasswordRevealed,
		});
	}
	changePasswordModal() {
		this.setState({ changePasswordModalShow: !this.state.changePasswordModalShow });
	}
	hideModalAccept(email) {
		let tokenValue = cookie.load("token");
		if (validateEmail(email) !== "Required" && validateEmail(email) !== "Invalid email address") {
			Axios({
				method: "post",
				url: baseURL + "/request_new_password",
				data: { email: email },
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + tokenValue,
				},
			})
				.then((response) => {
					this.handleNotificationClick("Email sent!", "success");
					setTimeout(() => {
						window.location.reload();
						this.changePasswordModal();
					}, 700);
				})
				.catch((error) => {
					if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
				});
		}
	}
	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};
	render() {
		return (
			<div>
				<Formik
					initialValues={{ email: "", password: "" }}
					validate={(values) => {
						const errors = {};
						if (!values.email) {
							errors.email = i18next.t("employee_form.email_placeholder");
						} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
							errors.email = i18next.t("employee_form.invalid_email");
						} else this.state.email = values.email;
						if (!values.password) {
							errors.password = i18next.t("login_page.password_placeholder");
						} else this.state.password = values.password;

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							alert(JSON.stringify(values, null, 2));
							setSubmitting(false);
						}, 400);
					}}
				>
					{({ isSubmitting }) => (
						<div className="inner-bg">
							<div className="container">
								{/* <div className="row">
                                    <div className="col-md-6 col-md-offset-3" >
                                        <h1><strong> {i18next.t('login_page.login')} </strong></h1>
                                    </div>
                                </div> */}
								<div className="row">
									<div className="col-sm-4 col-sm-offset-4 form-box">
										<div className="form-top">
											<div className="form-top-left">
												<h2>{i18next.t("login_page.login")}</h2>
												{/* <p>{i18next.t("login_page.enter_email")}:</p> */}
											</div>
										</div>
										<div className="form-bottom">
											<form action="" className="login-form">
												<div className="form-group">
													<label>{i18next.t("table_headers.email")}:</label>
													<Field type="email" name="email" className="form-control" placeholder={i18next.t("employee_form.email_placeholder")} />
													<ErrorMessage name="email" component="div" style={warningMessage} />
												</div>
												<div className="form-group">
													<label>{i18next.t("login_page.password")}:</label>
													<div style={{ position: "relative" }}>
														<Field type={this.state.isPasswordRevealed ? "text" : "password"} name="password" className="form-control" placeholder={i18next.t("login_page.password_placeholder")} />
														<span onClick={() => this.togglePassword()} ref={this.isPasswordRevealed}>
															<span>
																{this.state.isPasswordRevealed ? (
																	<FontAwesomeIcon icon={faEye} style={passwordRevealed}></FontAwesomeIcon>
																) : (
																	<FontAwesomeIcon icon={faEyeSlash} style={passwordRevealed}></FontAwesomeIcon>
																)}
															</span>
														</span>
													</div>
													<ErrorMessage name="password" component="div" style={warningMessage} />
												</div>
												<div className="row">
													<div className="text-center">
														<p onClick={this.changePasswordModal}>
															{i18next.t("login_page.password")} <strong>{i18next.t("login_page.forgot")}</strong>?
														</p>
													</div>
												</div>
												<div className="row">
													<Button onClick={() => this.props.login(this.state.email, this.state.password)} className="btn btn-danger btn-fill center-block" style={{ marginTop: 10 }}>
														{i18next.t("login_page.login").toUpperCase()}
													</Button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</Formik>
				<ForgotPasswordModal onAccept={this.hideModalAccept} show={this.state.changePasswordModalShow} onHide={this.changePasswordModal} />
				<NotificationSystem ref="notificationSystem" style={style} />
			</div>
		);
	}
}
export default LoginForm;
