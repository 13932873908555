import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
const resources = {
	en: {
		translation: {
			active_employees: {
				add_user: "ADD NEW USER",
				update_user: "UPDATE USER",
				delete_user: "DELETE USER",
				delete_user_check: "Are you sure you want to delete this user?",
				new_user: "NEW USER",
				days_left: "Days Left",
			},

			table_headers: {
				actions: "Actions",
				first_name: "First Name",
				last_name: "Last Name",
				email: "Email",
				action: "Action",
				start_date: "Start date",
				end_date: "End date",
				type: "Type",
				additional_message: "Additional message",
				attachments: "Attachments",
				working_days: "Working days",
				status: "Status",
			},

			filter_component: {
				filter: "Filter",
				all: "ALL",
				accepted: "ACCEPTED",
				rejected: "REJECTED",
				pending: "PENDING",
				canceled: "CANCELED",
				unpaid: "UNPAID",
				medical: "MEDICAL",
				maternity: "MATERNITY",
				paternity: "PATERNITY",
				bereavement: "BEREAVEMENT",
				marriage: "MARRIAGE",
				rest: "REST",
			},

			status_component: {
				accepted_table: "ACCEPTED",
				rejected_table: "REJECTED",
				pending_table: "PENDING",
				canceled_table: "CANCELED",
				working: "working",
				on_leave: "on leave",
			},

			dashboard_page: {
				holidays_calendar: "Holidays Calendar",
				pending_requests: "Pending Requests",
				message: "Message",
				no_pending_requests: "No pending requests",
			},

			employee_form: {
				no_supervisors: "No supervisors",
				email_required: "Email address is required",
				invalid_email: "Invalid email address",
				first_name_required: "First name is required",
				last_name_required: "Last name is required",
				leave_days_required: "Number of leave days is required",
				first_name_placeholder: "Enter first name",
				last_name_placeholder: "Enter last name",
				email_placeholder: "Enter email",
				none: "NONE",
				web: "WEB",
				mobile: "MOBILE",
				java: "JAVA",
				manager: "MANAGER",
				employee: "EMPLOYEE",
				current_days_left: "Current Days Left",
				hire_date: "Hire date",
				insert_employee: "INSERT EMPLOYEE",
				department: "Department",
				role: "Role",
				supervisor: "Supervisor",
				this_year_days: "Leave days(this year)",
				last_year_days: "Leave days(last year)",
				reset_password: "Reset password",
			},

			settings_page: {
				required_name: "Name is required",
				name_length: "Name is too long",
				period: "Period",
				name: "Name",
				add: "Add",
				delete_holiday: "DELETE HOLIDAY",
				delete_holiday_check: "Are you sure you want to delete this holiday?",
				period_settings: "Period is not valid",
				tab_title: "National Holidays",
			},

			departments_page: {
				delete_department: "DELETE DEPARTMENT",
				delete_department_check: "Are you sure you want to delete this department?",
			},

			profile_page: {
				update_profile: "UPDATE PROFILE",
				change_password: "Change password",
				here: "here",
				old_password: "Old password",
				new_password: "New password",
				new_password_confirm: "Confirm new password",
				update_password: "UPDATE PASSWORD",
				old_password_placeholder: "Insert old password",
				new_password_placeholder: "Insert new password",
				confirm_password_placeholder: "Confirm new password",
				empty_password: "Password can not be empty",
				different_password: "Passwords don`t match",
				email_ragex: "Minimum eight characters, one letter and one number",
			},

			login_page: {
				forgot_password: "Forgot password",
				send_email: "Send email",
				login: "Login",
				login_text: "Login to our site",
				enter_email: "Enter your email and password to log on",
				password: "Password",
				forgot: "forgotten",
				password_placeholder: "Enter password",
			},

			my_dashboard_page: {
				days_left_current_year: "Days remained this year",
				days_left_last_year: "Days left from last year",
				total_days: "Days in total",
				my_holidays_calendar: "My holidays calendar",
				create_request: "Create request",
				holiday_period: "Holiday period",
				Upload_file: "Upload file",
				create: "CREATE",
				holiday: "HOLIDAY",
				dropzone_message: "Drag and drop some files here, or click to select files",
			},

			sidebar: {
				my_dashboard: "My Dashboard",
				my_requests: "My Requests",
				my_profile: "My Profile",
				dashboard: "Dashboard",
				employees: "Employees",
				requests: "Requests",
				meal_vouchers: "Meal Vouchers",
				settings: "Settings",
				all_requests: "All Requests",
				HPMdashboard: "HPM Dashboard",
			},

			leave_management: {
				accept: "ACCEPT",
				decline: "DECLINE",
				cancel_leave: "CANCEL LEAVE",
				cancel_request_check: "Are you sure you want to cancel this request?",
			},

			footer: {
				about: "Despre",
				contact: "Contact",
			},
			search: "Search",

			calendar: {
				month: "Month",
				day: "Day",
				today: "Today",
				week: "Week",
			},
			meal_vouchers: {
				leave_days: "Leave days",
				days: "Days",
				work_days: "Worked days",
			},

			invalid_username_or_password: "Invalid User name or Password",
			invalid_informations: "Invalid informations",
			logout_succesfully: "Logout succesfully",
			unable_to_logout: "Unable to Logout",
			password_has_reseted: "Password has been reseted",
			email_not_found: "Email not found",
			user_inactive: "This user is inactive",
			user_not_found: "User not found",
			user_update_succesfully: "User updated successfully",
			user_deleted: "User deleted",
			user_not_enough_days: "Not enough days",
			holiday_created: "Holiday record created",
			holiday_update_succesfully: "Holiday updated successfully",
			holiday_not_found: "Holiday not found",
			holiday_deleted: "Holiday deleted",
			leave_overlapping: "Leave dates are overlapping",
			leave_created: "Leave record created",
			leave_not_found: "Request leave not found",
			leave_update_succesfully: "Leave updated successfully",
			leave_not_updated: "Leave can not be updated",
			leave_status_updated_succesfully: "Leave status updated successfully",
			department_not_found: "Department not found",
			department_is_assigned: "Department is assigned to user",
			department_deleted: "Department deleted",
			undefined: "UNDEFINED",

			employees_page: {
				active: "Active",
				inactive: "Inactive",
				activate_user: "ACTIVATE USER",
				activate_user_check: "Are you sure you want to activate this user?",
			},

			log_out: "Log out",

			modal_buttons: {
				yes: "Yes",
				close: "Close",
			},
		},
	},
	ro: {
		translation: {
			active_employees: {
				add_user: "UTILIZATOR NOU",
				update_user: "ACTUALIZARE UTILIZATOR",
				delete_user: "ȘTERGERE UTILIZATOR",
				delete_user_check: "Sigur doriți să ștergeți acest utilizator?",
				new_user: "UTILIZATOR NOU",
				days_left: "Zile rămase",
			},

			table_headers: {
				actions: "Acțiuni",
				first_name: "Prenume",
				last_name: "Nume",
				email: "Email",
				action: "Acțiune",
				start_date: "Data începerii",
				end_date: "Data încheierii",
				type: "Tip",
				additional_message: "Mesaj adițional",
				attachments: "Atașamente",
				working_days: "Zile lucrate",
				status: "Status",
			},

			filter_component: {
				filter: "Filtrare",
				all: "TOATE",
				accepted: "ACCEPTATE",
				rejected: "RESPINSE",
				pending: "ÎN AȘTEPTARE",
				canceled: "ANULATE",
				unpaid: "NEPLĂTIT",
				medical: "MEDICAL",
				maternity: "MATERNITATE",
				paternity: "PATERNITATE",
				bereavement: "DECES",
				marriage: "CĂSĂTORIE",
				rest: "ODIHNĂ",
			},
			status_component: {
				accepted_table: "ACCEPTATĂ",
				rejected_table: "RESPINSĂ",
				pending_table: "ÎN AȘTEPTARE",
				canceled_table: "ANULATĂ",
				working: "la birou",
				on_leave: "în concediu",
			},
			dashboard_page: {
				holidays_calendar: "Calendar zile libere",
				pending_requests: "Cereri în așteptare",
				message: "Mesaj",
				no_pending_requests: "Nu sunt cereri in așteptare",
			},

			employee_form: {
				no_supervisors: "Nu sunt supervizori",
				email_required: "Adresa de email obligatorie",
				invalid_email: "Adresa de email invalidă",
				first_name_required: "Prenumele este obligatoriu",
				last_name_required: "Numele este obligatoriu",
				leave_days_required: "Numărul de zile de concediu este obligatoriu",
				first_name_placeholder: "Introduceți prenumele",
				last_name_placeholder: "Introduceți numele",
				email_placeholder: "Introduceți adresă email",
				none: "NICIUNUL",
				web: "WEB",
				mobile: "MOBILE",
				java: "JAVA",
				manager: "MANAGER",
				employee: "ANGAJAT",
				current_days_left: "Zile Curente Rămase",
				hire_date: "Data angajării",
				insert_employee: "INSERARE",
				department: "Departament",
				role: "Rol",
				supervisor: "Supervizor",
				this_year_days: "Zile concediu(an curent)",
				last_year_days: "Zile concediu(an precedent)",
				reset_password: "Resetare parolă",
			},

			settings_page: {
				required_name: "Numele este obligatoriu",
				name_length: "Numele este prea mare",
				period: "Perioada",
				name: "Nume",
				add: "Inserare",
				delete_holiday: "ȘTERGERE SĂRBĂTOARE",
				delete_holiday_check: "Sigur doriți să ștergeți această sărbătoare?",
				period_settings: "Perioada nu este validă",
				tab_title: "Sărbători naționale",
			},

			departments_page: {
				delete_department: "ȘTERGERE DEPARTAMENT",
				delete_department_check: "Sigur doriți să ștergeți acest departament?",
			},

			profile_page: {
				update_profile: "ACTUALIZARE PROFIL",
				change_password: "Schimbare parolă",
				here: "aici",
				old_password: "Parolă veche",
				new_password: "Parolă nouă",
				new_password_confirm: "Confirmare parolă nouă",
				update_password: "ACTUALIZARE PAROLĂ",
				old_password_placeholder: "Parolă veche",
				new_password_placeholder: "Parolă nouă",
				confirm_password_placeholder: "Confirmare parolă nouă",
				empty_password: "Câmpul este gol",
				different_password: "Parolele trebuie sa fie identice",
				email_ragex: "Minim opt caractere, o literă și un număr",
			},

			login_page: {
				forgot_password: "Parolă uitată",
				send_email: "Trimitere email",
				login: "Autentificare",
				login_text: "Autentificați-vă pentru a continua",
				enter_email: "Introduceți adresa de email și parola",
				password: "Parolă",
				forgot: "uitată",
				password_placeholder: "Introduceți parola",
			},

			my_dashboard_page: {
				days_left_current_year: "Zile rămase în anul curent",
				days_left_last_year: "Zile rămase din anul precendent",
				total_days: "Număr total zile",
				my_holidays_calendar: "Vacanțele mele",
				create_request: "Creare cerere",
				holiday_period: "Perioadă concediu",
				Upload_file: "încărcare fișier",
				create: "CREARE",
				holiday: "Sărbători legale",
				dropzone_message: "Adaugați fișiere aici",
			},

			sidebar: {
				my_dashboard: "Acasă",
				my_requests: "Cererile mele",
				my_profile: "Profilul meu",
				dashboard: "Acasă",
				employees: "Angajati",
				requests: "Cereri concediu",
				meal_vouchers: "Tichete masa",
				settings: "Setări",
				all_requests: "Cereri concediu",
				HPMdashboard: "HPM Dashboard",
			},

			leave_management: {
				accept: "APROBARE",
				decline: "RESPINGERE",
				cancel_leave: "ANULARE CERERE",
				cancel_request_check: "Sigur doriți să anulați cererea?",
			},
			footer: {
				about: "Despre",
				contact: "Contact",
			},
			search: "Căutare",

			calendar: {
				month: "Lună",
				day: "Zi",
				today: "Astăzi",
				week: "Saptamână",
			},

			meal_vouchers: {
				leave_days: "Zile de concediu",
				days: "Zile",
				work_days: "Zile lucrate",
			},

			invalid_username_or_password: "Parolă sau email invalide",
			invalid_informations: "Informații invalide",
			logout_succesfully: "Deconectare cu succes",
			unable_to_logout: "Nu se poate efectua deconectarea",
			password_has_reseted: "Parola s-a resetat",
			email_not_found: "Emailul nu există",
			user_inactive: "Acest user este inactiv",
			user_not_found: "Userul nu s-a găsit",
			user_update_succesfully: "User actualizat cu succes",
			user_deleted: "User șters",
			user_not_enough_days: "Nu aveți destule zile",
			holiday_created: "Sărbătoarea s-a înregistrat",
			holiday_update_succesfully: "Sărbătoarea s-a actualizat",
			holiday_not_found: "Sărbătoarea nu s-a găsit",
			holiday_deleted: "Sărbătoarea a fost ștearsă",
			leave_overlapping: "Datele de concediu se suprapun",
			leave_created: "Cererea a fost creată",
			leave_not_found: "Cererea nu s-a găsit",
			leave_update_succesfully: "Cerere actualizată cu succes",
			leave_not_updated: "Cererea nu s-a actualizat",
			leave_status_updated_succesfully: "Statusul cererii s-a actualizat cu succes",
			department_not_found: "Department nu s-a găsit",
			department_is_assigned: "Department este asignat unui user",
			department_deleted: "Department șters",
			undefined: "NEDEFINIT",

			employees_page: {
				active: "Activi",
				inactive: "Inactivi",
				activate_user: "ACTIVARE UTILIZATOR",
				activate_user_check: "Sigur doriți să activați acest utilizator?",
			},

			modal_buttons: {
				yes: "Da",
				close: "Închidere",
			},

			log_out: "Deconectare",
		},
	},
};

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		resources,
		lng: localStorage.getItem("language"),
		fallbackLng: ["en", "ro"],
		//ns: ["translation"],
		// backend: {
		//   loadPath: '/locales/{{lng}}/{{ns}}.json'
		// },
	});
export default i18n;
