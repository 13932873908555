import React from "react";
import { Grid, Col, Row } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { style } from "variables/Variables.jsx";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import NotificationSystem from "react-notification-system";
import i18next from "i18next";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";

const MyInput = ({ form }) => {
	return (
		<Datetime
			closeOnClickOutside
			closeOnSelect
			dateFormat="YYYY-MM"
			timeFormat={false}
			value={form.values.period}
			onChange={(e) => {
				let dateValue = e;
				if (e instanceof moment) {
					dateValue = e;
				}
				form.setFieldValue("period", dateValue);
			}}
		/>
	);
};
class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			myclass: "",
			hidden: true,
			status: props.status,
		};
		this.divclicked = this.divclicked.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}
	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 8,
		});
	};
	divclicked() {
		if (this.state.myclass === "") {
			this.setState({
				myclass: "coolclass",
				hidden: false,
			});
		} else {
			this.setState({
				myclass: "",
				hidden: true,
			});
		}
	}

	render() {

		return (
			<div style={{ backgroundColor: "#d4d4d4", marginBottom: 10 }}>
				<NotificationSystem ref="notificationSystem" style={style} />
				<div onClick={this.divclicked}>
					<Grid fluid>
						<Row>
							<Col xs={10}>
								<label style={{ paddingTop: 8, paddingBottom: 8 }}>{i18next.t("filter_component.filter")}</label>
							</Col>
							<Col xs={2}>
								<span style={{ paddingTop: 15, float: "right" }} data-notify="icon" className="fas fa-angle-down" />
							</Col>
						</Row>
					</Grid>
				</div>
				<div hidden={this.state.hidden} className={this.state.myclass}>
					<Formik
						initialValues={{
							// startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
							// endDate: moment(this.state.endDate).format('YYYY-MM-DD'),
							type: "all",
							status: this.state.status,
							period: moment(),
						}}
						// validate={(values) => {
						// 	const errors = {};
						// 	return errors;
						// }}
						onSubmit={(values, { setSubmitting }) => {
							var begin = values.period.format("YYYY-MM-01");
							var end = values.period.format("YYYY-MM-") + values.period.daysInMonth();
							setTimeout(() => {
								this.props.loadData(begin, end, values.type.toLowerCase(), values.status);
								setSubmitting(false);
							}, 400);
						}}
					>
						{({ initialValues, values, setFieldValue, isSubmitting }) => (
							<Form>
								<div className="row" style={{ paddingLeft: 15 }}>
									<div className="form-group col-sm-2">
										<label htmlFor="period">Month:</label>
										<Field name="period" component={MyInput} />
									</div>
									{this.props.voucher === false && (
										<div className="form-group col-sm-2">
											<label htmlFor="satus">{i18next.t("table_headers.status")}:</label>
											<select
												id="satus"
												name="satus"
												className="form-control"
												value={values.status}
												onChange={(e) => {
													setFieldValue("status", e.target.value);
												}}
											>
												<option value="all">{i18next.t("filter_component.all")}</option>
												<option value="accepted">{i18next.t("filter_component.accepted")}</option>
												<option value="rejected">{i18next.t("filter_component.rejected")}</option>
												<option value="pending">{i18next.t("filter_component.pending")}</option>
												<option value="canceled">{i18next.t("filter_component.canceled")}</option>
											</select>
										</div>
									)}
									{this.props.voucher === false && (
										<div className="form-group col-sm-2">
											<label htmlFor="type">{i18next.t("table_headers.type")}:</label>
											<select
												id="type"
												name="type"
												className="form-control"
												value={values.type}
												onChange={(e) => {
													setFieldValue("type", e.target.value);
												}}
											>
												<option value="all">{i18next.t("filter_component.all")}</option>
												<option value="rest">{i18next.t("filter_component.rest")}</option>
												<option value="unpaid">{i18next.t("filter_component.unpaid")}</option>
												<option value="medical">{i18next.t("filter_component.medical")}</option>
												<option value="maternity">{i18next.t("filter_component.maternity")}</option>
												<option value="paternity">{i18next.t("filter_component.paternity")}</option>
												<option value="bereavement">{i18next.t("filter_component.bereavement")}</option>
												<option value="marriage">{i18next.t("filter_component.marriage")}</option>
											</select>
										</div>
									)}

									<div className="input-group" style={{ paddingLeft: 15, marginTop: 23 }}>
										<button style={{ marginLeft: 15, marginBottom: 15 }} className="btn btn-danger btn-fill" type="submit">
											{i18next.t("filter_component.filter").toUpperCase()}
										</button>
										<button
											type="button"
											style={{ marginLeft: 15, marginBottom: 15 }}
											className="btn btn-danger btn-fill "
											onClick={() => {
												setFieldValue("status", "all");
												setFieldValue("type", "all");
												setFieldValue(moment().format("YYYY-MM"), "period");
												// setFieldValue('startDate', initialValues.startDate);
												// setFieldValue('endDate', moment().format('YYYY-MM-DD'));
												this.props.loadData(moment().format("YYYY-MM-01"), moment().format("YYYY-MM-") + moment().daysInMonth(), "all", "all");
											}}
										>
											RESET
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}
}

export default App;
