import React from "react";
import DatetimeRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.css";

import { Button } from "react-bootstrap";

class DateRangePicker extends React.Component {
	constructor(props) {
		super(props);

		this.handleApply = this.handleApply.bind(this);

		this.state = {
			startDate: moment(),
			endDate: moment(),
			ranges: {
				Today: [moment(), moment()],
				Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
				"Last 7 Days": [moment().subtract(6, "days"), moment()],
				"Last 30 Days": [moment().subtract(29, "days"), moment()],
				"This Month": [moment().startOf("month"), moment().endOf("month")],
				"Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
			},
		};
	}

	handleApply(event, picker) {
		this.setState({
			startDate: picker.startDate,
			endDate: picker.endDate,
		});
		this.props.onNewRange(picker.startDate.format("YYYY-MM-DD"), picker.endDate.format("YYYY-MM-DD"));
	}

	inputPickerChangeFunc = (start, end) => {
		this.setState({
			start: start,
			end: end,
		});
	};

	render() {
		// let start = this.state.startDate.format("YYYY-MM-DD");
		// let end = this.state.endDate.format("YYYY-MM-DD");
		// let label;
		// if (start === end) {
		// 	label = start;
		// } else {
		// 	label = start + " - " + end;
		// }

		return (
			<div className="form-group">
				<div>
				<DatetimeRangePicker 
					locale={{firstDay: 1}}
					startDate={this.state.startDate} endDate={this.state.endDate} onApply={this.handleApply}>
						<div className="input-group">
							<input type="text" className="form-control" onChange={this.inputPickerChangeFunc} value={this.props.label} />
							<span className="input-group-btn">
								<Button className="default date-range-toggle">
									<i className="fa fa-calendar" />
								</Button>
							</span>
						</div>
					</DatetimeRangePicker>
				</div>
			</div>
		);
	}
}

export default DateRangePicker;
