import React, { Component } from 'react';
import '../../assets/css/Employees.css';
import axios from 'axios';
import { baseURL } from 'constants.js';
import cookie from 'react-cookies';
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import { Modal } from "react-bootstrap";
import CustomButton from 'components/CustomButton/CustomButton.jsx';
import DataTable from "react-data-table-component";
import i18next from 'i18next';
import UpdateEmployee from './UpdateEmployee';
function ActivateUserModal({ onActivate, ...props }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {i18next.t('employees_page.activate_user')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 id="deleteBody">
          {i18next.t('employees_page.activate_user_check')}
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton onClick={onActivate}>{i18next.t('modal_buttons.yes')}</CustomButton>
        <CustomButton onClick={props.onHide}>{i18next.t('modal_buttons.close')}</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}
function UpdateUserModal(props) {
	return (
		<Modal {...props} size="lg" id="modal-form" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header style={{ marginLeft: 15, marginRight: 15 }} closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("active_employees.update_user")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<UpdateEmployee
					userId={props.user.id}
					firstName={props.user.firstName}
					lastName={props.user.lastName}
					email={props.user.email}
					department={props.user.department}
					currentDaysLeft={props.user.currentDaysLeft}
					lastYearDays={props.user.lastYearDays}
					userRole={props.user.userRole}
					supervisor={props.user.supervisor}
					hireDate={props.user.created_at}
					onExit={props.onHide}
					isManager={props.user.isManager}
				></UpdateEmployee>
			</Modal.Body>
		</Modal>
  );
}
class InactiveEmployees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      activateUserModalShow: false,
      updateUserModal:false,
      userToUpdate: "",

      userIndexToActivate: -1,
      userToActivate: '',
      isActive: 1,

      columns: [
        {
          name: i18next.t('table_headers.last_name'),
          selector: 'lastName',
        },
        {
          name: i18next.t('table_headers.first_name'),
          selector: 'firstName',
        },
        {
          name: i18next.t('table_headers.email'),
          selector: 'email',
          grow: 2
        },
        {
          name: i18next.t('table_headers.action'),
          selector: 'actions',
        }
      ],
      rows: [],
      filterText: "",
      filteredItems: []
    };

    this.setActivateUserModalShow = this.setActivateUserModalShow.bind(this);
    this.onActivate = this.onActivate.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.subHeaderComponent = this.getSubHeaderComponent.bind(this);
    this.setUpdateUserModalShow = this.setUpdateUserModalShow.bind(this);
  }

  setActivateUserModalShow() {
    this.setState({ activateUserModalShow: !this.state.activateUserModalShow });
    return this.state.activateUserModalShow;
  }

  componentDidMount() {
    let tokenValue = cookie.load("token");
    let axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue,
      }
    };

    axios.get(baseURL + '/users_inactive', axiosConfig)
      .then(response => {
        if (response && response.data) {
          let resData = this.state;
          resData.rows = response.data;
          for (let i = 0; i < resData.rows.length; i++) {
            resData.rows[i]['actions'] = this.buildUsersTableActions(i)
          }
          this.setState({
            data: resData,
            filteredItems: this.state.rows
          })
        }
      })
      .catch(error => {
        if(error.response!==undefined)
          this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      });
  }

  buildUsersTableActions(index) {
    return (
      <div id="activateBtn">
        <i className="fas fa-user-check" onClick={() => this.verifyActivateUser(index)}></i>
      </div>
    )
  }

  verifyActivateUser = (index) => {
    this.setState({
      userIndexToActivate: index
    })
    let userId = this.state.rows[index].id;
    this.setState({
      userToActivate: userId,
      userToUpdate: this.state.rows[index]
    })
    this.setActivateUserModalShow();
  };

  onActivate() {
    const user = {
      isActive: this.state.isActive
    }
    let tokenValue = cookie.load("token");
    
    axios({
      method: 'put',
      url: baseURL + '/users/' + this.state.userToActivate,
      data: user,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue
      }
    })
      .then(response => {
        this.handleNotificationClick("Activated successfully!", "success");
        this.setState({
          updateUserModalShow:true,
          activateUserModalShow:false
        })
        // setTimeout(() => {
        //   window.location.reload();
        // }, 700);
      })
      .catch(error => {
        if(error.response!==undefined)
          this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      });
  }
  getSubHeaderComponent = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder={i18next.t("search")}
          onChange={(e) => {
            let newFilterText = e.target.value;
            this.state.filteredItems = this.state.rows.filter(
              (item) =>
                (item.firstName && item.firstName.toLowerCase().includes(this.state.filterText.toLowerCase())) ||
                (item.lastName && item.lastName.toLowerCase().includes(this.state.filterText.toLowerCase()))
            );
            this.setState({ filterText: newFilterText });
          }}
        />
      </div>
    );
  };
  handleNotificationClick = (msg, level) => {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        msg
      ),
      level: level,
      position: "tr",
      autoDismiss: 15
    });
  };
  setUpdateUserModalShow() {
    this.setState({ updateUserModalShow: !this.state.updateUserModalShow });
    window.location.reload();
		// return this.state.updateUserModalShow;
	}
  render() {
    return (
      <div className="mainDivStyle">
            <NotificationSystem ref="notificationSystem" style={style} />
            <UpdateUserModal 
            user={this.state.userToUpdate} 
            show={this.state.updateUserModalShow} 
            onHide={this.setUpdateUserModalShow} 
            refresh/>
            <ActivateUserModal onActivate={this.onActivate}
              show={this.state.activateUserModalShow}
              onHide={this.setActivateUserModalShow} />
            <DataTable
              columns={this.state.columns}
              data={this.state.filteredItems}
              pagination
              keyField="Leave_Id"
              striped
              responsive
              subHeaderComponent={this.getSubHeaderComponent()}
              subHeader
              noHeader
            />
      </div>
    )
  }
}

export default InactiveEmployees;

