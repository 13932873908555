import React from "react";
import AdminLayout from "layouts/Admin.jsx";
import LeaderLayout from "layouts/Supervisor";
import EmployeeLayout from "layouts/Employee";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class RedirectDashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			role: this.props.role
		};
	}
	checkRole() {
		if (this.state.role === "admin") {
			return (
				<Switch >
					<Route path="/admin" render={(props) => <AdminLayout {...props} />} />
					<Redirect from="/" to="/admin/dashboard" />
				</Switch>
			);
		}
		else
			if (this.state.role === "supervisor") {
				return (
					<Switch>
						<Route path="/supervisor" render={(props) => <LeaderLayout {...props} />} />
						<Redirect from="/" to="/supervisor/dashboard" />
					</Switch>
				)
			}
			else
				if (this.state.role === "employee") {
					return (
						<Switch>
							<Route path="/employee" render={(props) => <EmployeeLayout {...props} />} />
							<Redirect from="/" to="/employee/dashboard" />
						</Switch>
					)
				}

	}
	render() {
		return (
			<BrowserRouter>
				{this.checkRole()}
			</BrowserRouter>
		);
	}
}
export default RedirectDashboard;