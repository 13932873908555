import React from "react";
import { Modal } from "react-bootstrap";
import EmployeeForm from "../Admin/EmployeeForm";
import "../../assets/css/Employees.css";
import i18next from "i18next";

export default function AddUserModal(props) {
	return (
		<Modal {...props} aria-labelledby="modal-dialog modal-lg" id="modal-form">
			<Modal.Header style={{ marginLeft: 15, marginRight: 15 }} closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("active_employees.add_user")}</Modal.Title>
			</Modal.Header>
			<Modal.Body id="form-modal">
				<EmployeeForm departments={props.departments} supervisors={props.supervisors} onExit={props.onHide}></EmployeeForm>
			</Modal.Body>
		</Modal>
	);
}
