import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { baseURL } from 'constants.js';
import axios from "axios";
import cookie from 'react-cookies';
import { map } from 'constants.js';
import i18next from 'i18next';
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables";
import { Grid, Row } from "react-bootstrap";
import ColorsLegend from "./ColorsLegend";
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
moment.locale('en', {
  week: {
    dow: 1,
    doy: 1,
  },
});

moment.locale('ro', {
  week: {
    dow: 1,
    doy: 1,
  },
});

if (localStorage.getItem('language') === 'ro')
  moment.locale('ro', {
    months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembire'.split('_'),
    monthsParseExact: true,
    weekdays: 'Duminică_Luni_Marți_Miercuri_Joi_Vineri_Sămbătă'.split('_'),
    weekdaysShort: 'Dum._Lun._Mar._Mi._J._Vi._Sâm.'.split('_'),
    weekdaysMin: 'Du_Lu_Ma_Mi_J_Vi_Sâ'.split('_'),
    weekdaysParseExact: true,
  });
const localizer = momentLocalizer(moment)
const ColoredDateCellWrapper = ({
  range,
  value,
  children
}) => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return (
    React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: value < now ? '#e5e5e5' :
          { backgroundColor: value = now ? "#63d8f1" : 'white', }
      }
    }
    ))
};
class AllLeavesCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      _notificationSystem: null,
      loadedData: false,
      events: []
    }
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.loadData = this.loadData.bind(this);
    setTimeout(() => {
      this.loadData();
    }, 200);
  }

  handleNotificationClick = (msg, level) => {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        msg
      ),
      level: level,
      position: "tr",
      autoDismiss: 15,
    });
  };

  loadData() {
    // var today = new Date(),
    //   date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let tokenValue = cookie.load("token");
    axios({
      method: "post",
      url: baseURL + "/company_holidays",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + tokenValue,
      },
    })
      .then((response) => {
        if (response && response.data) {
          let events = this.state.events;
          for (let i = 0; i < response.data.length; i++) {
            let start = (new Date(`${response.data[i].startDate} 01:00:00`));
            let end = new Date(response.data[i].stopDate);
            events.push({
              title: response.data[i].name,
              startDate: start,
              endDate: end,
              hexColor: map["holiday"],
               allDay:true
            });
          }
          axios({
            method: 'post',
            url: baseURL + '/leaves_users',
            data: {
              "type": "all",
              "status": "accepted",
            },
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + tokenValue,
            }
          })
            .then(response => {
              if (response && response.data) {
                for (let i = 0; i < response.data.length; i++) {
                  let start = (new Date(`${response.data[i].startDate} 02:00:00`));
                  let end = new Date(response.data[i].stopDate);
                  events.push({
                    title: response.data[i].firstName + " " + response.data[i].lastName,
                    startDate: start,
                    endDate: end,
                    hexColor: map[response.data[i].type],
                    allDay:true
                  });
                }
                this.setState({
                  events: events,
                  loadedData: true,
                });
              }
            })
            .catch(error => {
              if (error.response !== undefined)
                this.handleNotificationClick(i18next.t(error.response.data.message), "error")
            });
        }
      })
      .catch((error) => {
        if (error.response.data !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
      });

    
  }

  render() {

    return (
      <div className="page-container">
        <NotificationSystem ref="notificationSystem" style={style} />
        {console.log(this.state.events)}
        {this.state.events.length>0? <Grid fluid>
          <Row>
            <div>
              {
                <Calendar className="center-block" style={{ height: 500, width: '95%' }}
                  selectable
                  localizer={localizer}
                  events={this.state.events}
                  startAccessor="startDate"
                  endAccessor="endDate"
                  views={{
                    week: true,
                    month: true,
                    day: true
                  }}
                  messages={{
                    month: i18next.t('calendar.month'),
                    day: i18next.t('calendar.day'),
                    today: i18next.t('calendar.today'),
                    week: i18next.t('calendar.week'),
                    previous: '<',
                    next: '>'
                  }}
                  eventPropGetter={
                    (event) => {
                      var style = {
                        backgroundColor: event.hexColor,
                        borderRadius: '10px',
                        opacity: 0.8,
                        color: 'black',
                        border: '0px',
                      };
                      return {
                        style: style
                      };
                    }
                  }

                  components={{
                    dateCellWrapper: ColoredDateCellWrapper
                  }}
                />}
            </div>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <ColorsLegend removePending={true}></ColorsLegend>
          </Row>
        </Grid>
          : <LoadingComponent />
        }
      </div>
    );


  }
}

export default AllLeavesCalendar;
