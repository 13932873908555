import React, { Component } from "react";
import { Formik, Field } from "formik";
import "../../assets/css/SettingsForm.css";
import DateRangePicker from "components/RangePickerForDate/DateRangePicker";
import axios from "axios";
import { baseURL, MAX_INPUT_LENGTH } from "constants.js";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import i18next from "i18next";
class SettingsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			name: "",
			startDate: "",
			stopDate: "",
		};
		this.createDay = this.createDay.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}

	inputPickerChange = (startDate, stopDate) => {
		this.setState({
			startDate: startDate,
			stopDate: stopDate,
		});
	};

	createDay() {
		let day = {
			name: this.state.name,
			startDate: this.state.startDate,
			stopDate: this.state.stopDate,
		};
		let tokenValue = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/holidays",
			data: day,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Holiday added successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		return (
			<div>
				<NotificationSystem ref="notificationSystem" style={style} />
				<Formik
					initialValues={{ name: "", period: "" }}
					validate={(values) => {
						const errors = {};
						if (!values.name) {
							errors.name = i18next.t("settings_page.required_name");
						} else if (values.name.length > MAX_INPUT_LENGTH) {
							errors.name = i18next.t("settings_page.name_length");
						} else {
							this.setState({ name: values.name });
						}
						if (values.period === "") errors.period = i18next.t("settings_page.period_settings");
						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							this.createDay();
							setSubmitting(false);
						}, 400);
					}}
				>
					{(props) => (
						<div>
							<div id="rowStyleForm">
								<form
									style={{
										padding: 10,
										border: "1px solid lightgrey ",
										borderRadius: 5,
									}}
									onSubmit={props.handleSubmit}
								>
									<div className="form-group col-md-12">
										<label htmlFor="period">{i18next.t("settings_page.period")}:</label>
										<DateRangePicker
											label={this.state.startDate + " - " + this.state.stopDate}
											onNewRange={(startDate, endDate) => {
												this.inputPickerChange(startDate, endDate);
												props.setFieldValue("period", startDate + "-" + startDate);
												props.validateForm();
											}}
										></DateRangePicker>
										{props.errors.period && <div className="warningMessage">{props.errors.period}</div>}
									</div>
									<div className="form-group col-md-12">
										<label htmlFor="name">{i18next.t("settings_page.name")}:</label>
										<Field type="text" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.name} name="name" className="form-control" />
										{props.errors.name && <div className="warningMessage">{props.errors.name}</div>}
									</div>
									<div className="row">
										<button className="btn btn-danger btn-fill center-block" type="submit">
											{i18next.t("settings_page.add")}
										</button>
									</div>
								</form>
							</div>
						</div>
					)}
				</Formik>
			</div>
		);
	}
}

export default SettingsForm;
