import React, { Component } from "react";
import { Formik, Field } from "formik";
import "../../assets/css/SettingsForm.css";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import i18next from "i18next";
class DepartmentsForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			name: "",
			startDate: "",
			stopDate: "",
		};
		this.addDepartment = this.addDepartment.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}

	inputPickerChange = (startDate, stopDate) => {
		this.setState({
			startDate: startDate,
			stopDate: stopDate,
		});
	};

	addDepartment(values) {
		let tokenValue = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/department",
			data: { name: values.name },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Department added successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		return (
			<div>
				<NotificationSystem ref="notificationSystem" style={style} />
				<Formik
					initialValues={{ name: "" }}
					validate={(values) => {
						const errors = {};
						if (values.name === "") errors.name = i18next.t("settings_page.required_name");
						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						setTimeout(() => {
							this.addDepartment(values);
							setSubmitting(false);
						}, 400);
					}}
				>
					{(props) => (
						<div>
							<div id="rowStyleForm">
								<form
									style={{
										padding: 10,
										border: "1px solid lightgrey ",
										borderRadius: 5,
									}}
									onSubmit={props.handleSubmit}
								>
									<div className="form-group col-md-12">
										<label htmlFor="name">{i18next.t("settings_page.name")}:</label>
										<Field type="text" onChange={props.handleChange} onBlur={props.handleBlur} value={props.values.name} name="name" className="form-control" />
										{props.errors.name && <div className="warningMessage">{props.errors.name}</div>}
									</div>

									<div className="row">
										<button className="btn btn-danger btn-fill center-block" type="submit">
											{i18next.t("settings_page.add")}
										</button>
									</div>
								</form>
							</div>
						</div>
					)}
				</Formik>
			</div>
		);
	}
}

export default DepartmentsForm;
