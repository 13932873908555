import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import cookie from "react-cookies";
import Moment from "moment";
import axios from "axios";
import { baseURL } from "constants.js";
import NotificationSystem from "react-notification-system";
import { style, warningMessage } from "variables/Variables.jsx";
import { Button, Row, Grid } from "react-bootstrap";
import Axios from "axios";
import i18next from "i18next";
import { Multiselect } from "multiselect-react-dropdown";
export class UpdateEmployee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			firstNameOriginal: props.firstName,
			lastNameOriginal: props.lastName,
			emailOriginal: props.email,
			departmentOriginal: props.department,
			currentDaysLeftOriginal: props.currentDaysLeft,
			lastYearDaysOriginal: props.lastYearDays,
			roleOriginal: props.userRole,
			supervisorOriginal: props.supervisor,
			isManagerOriginal: props.isManager,
			hireDateOriginal: Moment(props.hireDate).format("YYYY-MM-DD"),
			supervisors: props.supervisors,
			departments: props.departments,
			user: {
				firstName: props.firstName,
				lastName: props.lastName,
				email: props.email,
				department: props.department,
				userRole: props.userRole,
				currentDaysLeft: props.currentDaysLeft,
				lastYearDays: props.lastYearDayss,
				supervisor: props.supervisor,
				isManager: props.isManager,
			},
		};
		this.onSubmitButton = this.onSubmitButton.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.createSupervisors = this.createSupervisors.bind(this);
	}

	onSubmitButton(values) {
		const user = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			department: values.department,
			userRole: values.role,
			currentDaysLeft: values.currentDaysLeft,
			supervisorID: values.supervisor.map((data) => {
				return data.id;
			}),
			isManager: values.isManager,
			hireDate: values.hireDate,
			lastYearDays: values.lastYearDays,
		};
		let tokenValue = cookie.load("token");
		axios({
			method: "put",
			url: baseURL + "/users/" + this.props.userId,
			data: user,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Updated successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	resetPassword() {
		let tokenValue = cookie.load("token");
		Axios({
			method: "post",
			url: baseURL + "/request_new_password",
			data: { email: this.state.user.email },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Email sent!", "success");
				setTimeout(() => {
					window.location.reload();
					this.changePasswordModal();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	createSupervisors() {
		return this.state.supervisorOriginal
			.map((id) => this.state.supervisors.find((x) => x.id == id))
			.map((x) => {
				return { id: x.id, firstName: x.firstName };
			});
	}

	render() {
		return (
			<div className="content">
				<NotificationSystem ref="notificationSystem" style={style} />
				<div>
					<Formik
						initialValues={{
							firstName: this.state.firstNameOriginal,
							lastName: this.state.lastNameOriginal,
							email: this.state.emailOriginal,
							department: this.state.departmentOriginal,
							role: this.state.roleOriginal,
							supervisor: this.state.supervisorOriginal ? this.createSupervisors() : this.state.supervisors[0].id,
							password: this.state.passwordOriginal,
							lastYearDays: this.state.lastYearDaysOriginal,
							currentDaysLeft: this.state.currentDaysLeftOriginal,
							hireDate: this.state.hireDateOriginal,
							isManager: this.state.isManagerOriginal,
						}}
						validate={(values) => {
							const errors = {};
							if (!values.email) {
								values.email = this.state.emailOriginal;
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = "Invalid email address";
							}
							if (!values.firstName) {
								values.firstName = this.state.firstNameOriginal;
							}
							if (!values.lastName) {
								values.lastName = this.state.lastNameOriginal;
							}
							if (!values.lastYearDays) {
								values.lastYearDays = this.state.lastYearDaysOriginal;
							}
							if (!values.currentDaysLeft) {
								values.currentDaysLeft = this.state.currentDaysLeftOriginal;
							}
							return errors;
						}}
						onSubmit={(values) => {
							this.onSubmitButton(values);
						}}
					>
						{({ setFieldValue, values, isSubmitting }) => (
							<Form className="center-block">
								<div className="col-md-6" style={{ marginBottom: 15 }}>
									<label htmlFor="firstName">{i18next.t("table_headers.first_name")}:</label>
									<Field type="name" className="form-control" name="firstName" />
									<ErrorMessage name="firstName" component="div" style={warningMessage} />

									<label htmlFor="lastName">{i18next.t("table_headers.last_name")}:</label>
									<Field type="name" name="lastName" className="form-control" />
									<ErrorMessage name="lastName" component="div" style={warningMessage} />

									<label>{i18next.t("table_headers.email")}:</label>
									<Field type="email" name="email" className="form-control" />
									<ErrorMessage name="email" component="div" style={warningMessage} />

									<label htmlFor="isManager">{i18next.t("employee_form.role")}:</label>
									<select id="isManager" name="isManager" className="form-control" value={values.isManager} onChange={(e) => setFieldValue("isManager", e.target.value)}>
										<option value="0">{i18next.t("employee_form.employee")}</option>
										<option value="1">{i18next.t("employee_form.manager")}</option>
									</select>
								</div>

								<div className="col-md-6" style={{ marginBottom: 15 }}>
									<label style={{ marginTop: 15 }}>{i18next.t("employee_form.hire_date")}:</label>
									<Field
										type="date"
										name="hireDate"
										value={values.hireDate}
										className="form-control"
										onChange={(e) => {
											setFieldValue("hireDate", e.target.value);
										}}
									/>

									<label htmlFor="department">{i18next.t("department")}:</label>
									<select
										id="department"
										name="department"
										className="form-control"
										value={values.department}
										onChange={(e) => {
											setFieldValue("department", e.target.value);
										}}
									>
										{this.state.departments.map((item, index) => (
											<option key={index} value={item.id}>
												{item.name}
											</option>
										))}
									</select>

									<label htmlFor="supervisor">{i18next.t("employee_form.supervisor")}:</label>
									<Multiselect
										id="supervisor"
										name="supervisor"
										className="form-control"
										options={this.state.supervisors}
										avoidHighlightFirstOption
										onRemove={(e) => {
											setFieldValue("supervisor", e);
										}}
										onSelect={(e) => {
											setFieldValue("supervisor", e);
										}}
										displayValue="firstName"
										selectedValues={values.supervisor}
									></Multiselect>

									<label>{i18next.t("employee_form.this_year_days")}:</label>
									<Field type="name" name="currentDaysLeft" className="form-control" value={values.currentDaysLeft} />
									<ErrorMessage name="currentDaysLeft" component="div" style={warningMessage} />

									<label>{i18next.t("employee_form.last_year_days")}:</label>
									<Field type="name" name="lastYearDays" className="form-control" value={values.lastYearDays} />
									<ErrorMessage name="lastYearDays" component="div" style={warningMessage} />
								</div>

								<hr
									style={{
										marginTop: 10,
										color: "#e4e5e4",
										width: "100%",
									}}
								/>
								<Grid>
									<Row>
										<Button style={{ marginRight: 10 }} onClick={this.resetPassword}>
											{i18next.t("employee_form.reset_password")}
										</Button>
										<Button type="submit" style={{ marginRight: 10 }} disabled={isSubmitting}>
											{i18next.t("active_employees.update_user")}
										</Button>
										<Button onClick={this.props.onExit} style={{ marginRight: 0 }}>
											{i18next.t("modal_buttons.close")}
										</Button>
									</Row>
								</Grid>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		);
	}
}

export default UpdateEmployee;
