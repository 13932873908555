import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import cookie from "react-cookies";
import axios from "axios";
import { baseURL } from "constants.js";
import NotificationSystem from "react-notification-system";
import { style, warningMessage } from "variables/Variables.jsx";
import moment from "moment";
import { Button, Row, Grid } from "react-bootstrap";
import i18next from "i18next";
import { Multiselect } from "multiselect-react-dropdown";
export class EmployeeForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			supervisors: props.supervisors,
			_notificationSystem: null,
			firstName: "",
			lastName: "",
			email: "",
			department: "None",
			userRole: "employee",
			currentDaysLeft: "",
			lastYearDays: "",
			supervisor: [],
			isManager: "0",
			hireDate: moment().format("YYYY-MM-DD"),
			departments: props.departments,
		};
		this.onSubmitButton = this.onSubmitButton.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
	}

	onSubmitButton(values) {
		const user = {
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			department: values.department,
			userRole: values.role,
			currentDaysLeft: values.currentDaysLeft,
			supervisorID: values.supervisor.map((data) => {
				return data.id ;
			}),
			isManager: values.isManager,
			hireDate: values.hireDate,
			lastYearDays: values.lastYearDays,
		};
		let tokenValue = cookie.load("token");
		let axiosConfig = {
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		};
		axios
			.post(baseURL + "/register", user, axiosConfig)
			.then((response) => {
				this.handleNotificationClick("Registered successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		console.log();
		return (
			this.state.supervisor &&
			this.state.departments && (
				<div>
					<NotificationSystem ref="notificationSystem" style={style} />
					<div>
						<Formik
							initialValues={{
								firstName: this.state.firstName,
								lastName: this.state.lastName,
								email: this.state.email,
								currentDaysLeft: 0,
								department: this.state.departments[0].id,
								isManager: this.state.isManager,
								supervisor: this.state.supervisor,
								role: this.state.userRole,
								hireDate: this.state.hireDate,
								lastYearDays: 0,
							}}
							validate={(values) => {
								const errors = {};
								if (!values.email) {
									errors.email = i18next.t("employee_form.email_required");
								} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
									errors.email = i18next.t("employee_form.invalid_email");
								}
								if (!values.firstName) {
									errors.firstName = i18next.t("employee_form.first_name_required");
								}
								if (!values.lastName) {
									errors.lastName = i18next.t("employee_form.last_name_required");
								}
								if (!values.currentDaysLeft) {
									errors.currentDaysLeft = i18next.t("employee_form.leave_days_required");
								}

								return errors;
							}}
							onSubmit={(values) => {
								this.onSubmitButton(values);
							}}
						>
							{({ setFieldValue, values, isSubmitting }) => (
								<Form className="center-block" id="formId">
									<div className="col-md-6" style={{ marginBottom: 15 }}>
										<label htmlFor="firstName" style={{ marginTop: 15 }}>
											{i18next.t("table_headers.first_name")}:
										</label>
										<Field type="name" className="form-control" name="firstName" placeholder={i18next.t("employee_form.first_name_placeholder")} />
										<ErrorMessage name="firstName" component="div" style={warningMessage} />

										<label htmlFor="lastName" style={{ marginTop: 15 }}>
											{i18next.t("table_headers.last_name")}:
										</label>
										<Field type="name" name="lastName" className="form-control" placeholder={i18next.t("employee_form.last_name_placeholder")} />
										<ErrorMessage name="lastName" component="div" style={warningMessage} />

										<label style={{ marginTop: 15 }}>{i18next.t("table_headers.email")}:</label>
										<Field type="email" name="email" className="form-control" placeholder={i18next.t("employee_form.email_placeholder")} />
										<ErrorMessage name="email" component="div" style={warningMessage} />

										<label htmlFor="isManager" style={{ marginTop: 15 }}>
											{i18next.t("employee_form.role")}:
										</label>
										<select id="isManager" name="isManager" className="form-control" onChange={(e) => setFieldValue("isManager", e.target.value)}>
											<option value="0">{i18next.t("employee_form.employee")}</option>
											<option value="1">{i18next.t("employee_form.manager")}</option>
										</select>
									</div>
									<div className="col-md-6" style={{ marginBottom: 15 }}>
										<label style={{ marginTop: 15 }}>{i18next.t("employee_form.hire_date")}:</label>
										<Field
											type="date"
											name="hireDate"
											value={values.hireDate}
											className="form-control"
											onChange={(e) => {
												setFieldValue("hireDate", e.target.value);
											}}
										/>

										<label htmlFor="department" style={{ marginTop: 15 }}>
											{i18next.t("employee_form.department")}:
										</label>
										<select
											id="department"
											name="department"
											className="form-control"
											value={values.department}
											onChange={(e) => {
												setFieldValue("department", e.target.value);
											}}
										>
											{this.state.departments.map((item, index) => (
												<option key={index} value={item.id}>
													{item.name}
												</option>
											))}
										</select>
										<label htmlFor="supervisor" style={{ marginTop: 15 }}>
											{i18next.t("employee_form.supervisor")}:
										</label>
										<Multiselect
											id="supervisor"
											name="supervisor"
											className="form-control"
											options={this.state.supervisors}
											avoidHighlightFirstOption
											onRemove={(e) => {
												setFieldValue("supervisor", e);
											}}
											onSelect={(e) => {
												setFieldValue("supervisor", e);
											}}
											displayValue="firstName"
										></Multiselect>

										<label htmlFor="currentDaysLeft" style={{ marginTop: 15 }}>
											{i18next.t("employee_form.current_days_left")}:
										</label>
										<Field type="number" className="form-control" name="currentDaysLeft" />
										<ErrorMessage name="currentDaysLeft" component="div" style={warningMessage} />

										<label style={{ marginTop: 15 }}>{i18next.t("employee_form.last_year_days")}:</label>
										<Field type="number" name="lastYearDays" className="form-control" />
										<ErrorMessage name="lastYearDays" component="div" style={warningMessage} />
									</div>
									<hr
										style={{
											color: "#e4e5e4",
											width: "100%",
										}}
									/>
									<Grid>
										<Row>
											<Button type="submit" style={{ marginRight: 10 }} disabled={isSubmitting}>
												{i18next.t("employee_form.insert_employee")}
											</Button>
											<Button onClick={this.props.onExit}>{i18next.t("modal_buttons.close")}</Button>
										</Row>
									</Grid>
								</Form>
							)}
						</Formik>
					</div>
				</div>
			)
		);
	}
}

export default EmployeeForm;
