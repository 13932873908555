// url to the backend server
export const baseURL = "https://holiday-api.hpm.ro/api";
export const MAX_INPUT_LENGTH = 50;
export const colors = ["#ef4446", "#86cfca", "#708dc8", "#008081", "#89cfee", "#4d516e", "#088dca", "#2d7689","#F9c70f"];
const colorMap = {};
colorMap["holiday"] = colors[0];
colorMap["medical"] = colors[1];
colorMap["unpaid"] = colors[2];
colorMap["maternity"] = colors[3];
colorMap["paternity"] = colors[4];
colorMap["bereavement"] = colors[5];
colorMap["rest"] = colors[6];
colorMap["marriage"] = colors[7];
colorMap["pending"] = colors[8];
export const map = colorMap;
export const marriage = 5;
export const bereavement = 3;
export const maternity = 365;
export const paternity = 14;
export const maxAttachments = 3;
export const fileSize = 2000000;
