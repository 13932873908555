import DashboardAdmin from "./components/Admin/DashboardAdmin.jsx";
import DashboardEmployee from "views/DashboardEmployee";
import DashboardSupervisor from "views/DashboardSupervisor";
import UserProfile from "views/UserProfile.jsx";
import Employees from "components/Admin/Employees";
import AllRequests from "views/AllRequests";
import MyRequests from "views/MyRequests";
import Settings from "components/Admin/Settings";
import AdminRequests from "components/Admin/AdminRequests.jsx";
import MealVouchers from "components/Admin/MealVouchers.jsx"
import AllLeavesCalendar from "components/Calendar/AllLeavesCalendar.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "dashboard",
    icon: "pe-7s-graph",
    component: DashboardAdmin,
    layout: "/admin"
  },
  {
    path: "/employees",
    name: "employees",
    icon: "pe-7s-users",
    component: Employees,
    layout: "/admin"
  },
  {
    path: "/requests",
    name: "requests",
    icon: "pe-7s-albums",
    component: AdminRequests,
    layout: "/admin"
  },
  {
    path: "/mealVouchers",
    name: "meal_vouchers",
    icon: "pe-7s-cash",
    component: MealVouchers,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "settings",
    icon: "pe-7s-settings",
    component: Settings,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "my_profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    icon: "pe-7s-graph",
    component: DashboardSupervisor,
    layout: "/supervisor"
  },
  {
    path: "/settings",
    name: "all_requests",
    icon: "pe-7s-albums",
    component: AllRequests,
    layout: "/supervisor"
  },
  
  {
    path: "/mydashboard",
    name: "my_dashboard",
    icon: "pe-7s-graph",
    component: DashboardEmployee,
    layout: "/supervisor"
  },
  {
    path: "/requests",
    name: "my_requests",
    icon: "pe-7s-id",
    component: MyRequests,
    layout: "/supervisor"
  },
 
  {
    path: "/user",
    name: "my_profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/supervisor"
  },
  {
    path: "/allLeaves",
    name: "HPMdashboard",
    icon: "pe-7s-sun",
    component: AllLeavesCalendar,
    layout: "/supervisor"
  },
  {
    path: "/dashboard",
    name: "my_dashboard",
    icon: "pe-7s-graph",
    component: DashboardEmployee,
    layout: "/employee"
  },
  {
    path: "/requests",
    name: "my_requests",
    icon: "pe-7s-id",
    component: MyRequests,
    layout: "/employee"
  },
  {
    path: "/user",
    name: "my_profile",
    icon: "pe-7s-user",
    component: UserProfile,
    layout: "/employee"
  },
  {
    path: "/allLeaves",
    name: "HPMdashboard",
    icon: "pe-7s-sun",
    component: AllLeavesCalendar,
    layout: "/employee"
  },

];

export default dashboardRoutes;
