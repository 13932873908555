import React from 'react';
import DatetimeRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
    Button,
} from 'react-bootstrap';

class DateRangePicker extends React.Component {

    constructor(props) {
        super(props);

        this.handleApply = this.handleApply.bind(this);
        this.state = {
            label: props.label,
            startDate: moment().subtract(29, 'days'),
            endDate: moment(),
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        };
    }
    handleApply(event, picker) {
        let start = picker.startDate.format('YYYY-MM-DD');
        let end = picker.endDate.format('YYYY-MM-DD');
        let labelUpdated = start + ' - ' + end;
        if (start === end) {
            labelUpdated = start;
        }
        this.setState({
            label: labelUpdated,
            startDate: picker.startDate,
            endDate: picker.endDate,
        });
        this.setState({
            label: labelUpdated,
        });
        this.props.onNewRange(labelUpdated, picker.startDate + "," + picker.endDate);
    }

    render() {
        return (
            <div className="form-group">
                <DatetimeRangePicker
                locale={{firstDay: 1}}
                    onApply={this.handleApply}
                >
                    <div className="input-group">
                        <input type="text" className="form-control" value={this.props.label}
                        onChange={()=>{}}
                        />
                        <span className="input-group-btn">
                            <Button className="default date-range-toggle">
                                <i className="fa fa-calendar" />
                            </Button>
                        </span>
                    </div>
                </DatetimeRangePicker>
            </div>
        );
    }
}

export default DateRangePicker;