import React from 'react';
import InactiveEmployees from "./InactiveEmployees";
import ActiveEmployees from "./ActiveEmployees";
import {Tabs, TabLink, TabContent} from 'react-tabs-redux';
import '../../assets/css/EmployeesNavbar.css';
import i18next from 'i18next';
export default function EmployeesNavbar() {
    return (    
            <Tabs>
                <div id="divTabs">
                    <TabLink className="tabLink" to="active">{i18next.t('employees_page.active')}</TabLink>
                    <TabLink className="tabLink" to="inactive">{i18next.t('employees_page.inactive')}</TabLink>
                </div>
         
                <TabContent for="active">
                    <ActiveEmployees>
                    </ActiveEmployees>
                </TabContent>
                <TabContent for="inactive">
                    <InactiveEmployees>
                    </InactiveEmployees>
                </TabContent>
            </Tabs>
    );
}