import React, { Component } from "react";
import MyProfile from "components/ProfileForm/MyProfile";

class UserProfile extends Component {
  render() {
    return (
      <div className="page-container">
        <MyProfile></MyProfile>
      </div>
    );
  }
}

export default UserProfile;
