import React, { Component } from "react";
import i18next from "i18next";
import { Button } from "react-bootstrap";
import { LightTooltip } from "components/CustomTooltip";

class SubHeaderNoExport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			allData: props.allData,
			filterText: props.filterText,
			insertButton: props.insertButton,
			dataToDownload: props.dataToDownload,
		};

		this.getSubHeaderComponent = this.getSubHeaderComponent.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.allData !== this.state.allData) {
            this.setState({ allData: nextProps.allData });
          }
		  if (nextProps.filterText !== this.state.filterText) {
            this.setState({ filterText:nextProps.filterText });
          }
	}

	getSubHeaderComponent = () => {
		return (
			<div style={{ width: "inherit" }}>
				{!this.state.dataToDownload ? (
					<Button style={{ float: "left", visibility: this.state.insertButton }} className="btn btn-danger btn-fill" onClick={this.props.setAddUserModalShow}>
						{i18next.t("active_employees.new_user")}
					</Button>
				) : (
					<LightTooltip title="Download EXCEL">
						<a key={1} href={this.state.dataToDownload} role="button" className="btn btn-danger btn-fill" style={{ float: "left" }} target="_self" rel="noopener noreferrer">
							<i class="fas fa-file-excel" style={{ marginRight: 5 }}></i>
							Export all
						</a>
					</LightTooltip>
				)}
				<input
					style={{ float: "right", width: 160 }}
					className="form-control"
					placeholder={i18next.t("search")}
					onChange={(e) => {
						let newFilterText = e.target.value;
						let newItems = this.state.allData.filter(
							(item) =>
								(item.firstName && item.firstName.toLowerCase().includes(newFilterText.toLowerCase())) ||
								// (item.type && item.type.toLowerCase().includes(newFilterText.toLowerCase())) ||
								(item.lastName && item.lastName.toLowerCase().includes(newFilterText.toLowerCase()))
						);
						this.props.updateData(newFilterText, newItems);
					}}
					value={this.state.filterText}
				/>
			</div>
		);
	};
	render() {
		return <div style={{ width: "inherit" }}>{this.getSubHeaderComponent()}</div>;
	}
}
export default SubHeaderNoExport;
