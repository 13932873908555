import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import { Modal } from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import DataTable from "react-data-table-component";
import i18next from "i18next";

function DeleteHolidayModal({ onDelete, ...props }) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("departments_page.delete_department")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3 id="deleteBody">{i18next.t("departments_page.delete_department_check")}</h3>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton onClick={onDelete}>{i18next.t("modal_buttons.yes")}</CustomButton>
				<CustomButton onClick={props.onHide}> {i18next.t("modal_buttons.close")}</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

class DepartmentsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			deleteDepartmentModalShow: false,
			departmentToDelete: "",
			columns: [
				{
					name: i18next.t("settings_page.name"),
					selector: "name",
				},
				{
					name: i18next.t("table_headers.action"),
					selector: "actions",
				},
			],
		};
		this.setDeleteHolidayModalShow = this.setDeleteHolidayModalShow.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.verifyDeleteDepartment = this.verifyDeleteDepartment.bind(this);
	}

	setDeleteHolidayModalShow() {
		this.setState({ deleteDepartmentModalShow: !this.state.deleteDepartmentModalShow });
		return this.state.deleteDepartmentModalShow;
	}

	componentDidMount() {
		setTimeout(() => {
			let tokenValue = cookie.load("token");
			axios({
				method: "GET",
				url: baseURL + "/departments",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + tokenValue,
				},
			})
				.then((response) => {
					if (response && response.data) {
						for (let i = 0; i < response.data.length; i++) {
							response.data[i]["actions"] = this.buildUsersTableActions(i);
						}
						this.setState({
							data: response.data,
						});
					}
				})
				.catch((error) => console.log(error));
		}, 200);
	}

	buildUsersTableActions(index) {
		return (
			<div id="activateBtn">
				<i className="fa fa-trash" aria-hidden="true" onClick={() => this.verifyDeleteDepartment(index)}></i>
			</div>
		);
	}

	verifyDeleteDepartment = (index) => {
		this.setState({
			departmentToDelete: this.state.data[index].id,
		});
		this.setDeleteHolidayModalShow();
	};

	onDelete() {
		let tokenValue = cookie.load("token");
		axios({
			method: "delete",
			url: baseURL + "/departments/" + this.state.departmentToDelete,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick(i18next.t(response.data.message), "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};

	render() {
		return (
			<div className="mainDivStyle">
				<NotificationSystem ref="notificationSystem" style={style} />
				<DeleteHolidayModal onDelete={this.onDelete} show={this.state.deleteDepartmentModalShow} onHide={this.setDeleteHolidayModalShow} />
				<DataTable columns={this.state.columns} pagination data={this.state.data} keyField="value" striped responsive noHeader />
			</div>
		);
	}
}

export default DepartmentsTable;
