import React, { Component } from "react";
import AllRequestsTable from "./AllRequestsTable";


class AdminRequests extends Component {
  
  render() {
    return (
      <div>
        <AllRequestsTable></AllRequestsTable>
      </div>
    );
  }
}

export default AdminRequests;
