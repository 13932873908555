import React, { Component } from "react";
import axios from 'axios';
import { baseURL } from 'constants.js';
import cookie from 'react-cookies'
import NotificationSystem from "react-notification-system";
import { style} from "variables/Variables.jsx";
import { Modal } from "react-bootstrap";
import CustomButton from 'components/CustomButton/CustomButton.jsx';
import DataTable from "react-data-table-component";
import FiltersComponent from "../Admin/FiltersComponent"
import moment from 'moment';
import LoadingComponent from "components/LoadingComponent/LoadingComponent";
import i18next from 'i18next';
import StatusComponenet from "components/StatusLabelComponent/StatusComponent"
function CancelLeaveModal({ onCancelLeave, ...props }) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {i18next.t('leave_management.cancel_leave')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3 id="deleteBody">
          {i18next.t('leave_management.cancel_request_check')}
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton onClick={onCancelLeave}> {i18next.t('modal_buttons.yes')}</CustomButton>
        <CustomButton onClick={props.onHide}>{i18next.t('modal_buttons.close')}</CustomButton>
      </Modal.Footer>
    </Modal>
  );
}

class AllRequestsSupervisor extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      cancelLeaveModalShow: false,
      status: 'canceled',

      requestIndexToCancel: -1,
      requestToCancel: '',

      requestIndexToDownload: -1,
      requestToDownload: '',

      columns: [
        {
          name: i18next.t('table_headers.last_name'),
          selector: 'lastName'
        },
        {
          name: i18next.t('table_headers.first_name'),
          selector: 'firstName'
        },
        {
          name: i18next.t('table_headers.email'),
          selector: 'email'
        },
        {
          name: i18next.t('table_headers.type'),
          selector: 'type'
        },
        {
          name: i18next.t('table_headers.start_date'),
          selector: 'startDate'
        },
        {
          name: i18next.t('table_headers.end_date'),
          selector: 'stopDate'
        },
        {
          name: i18next.t('table_headers.status'),
          selector: 'status'
        },
        {
          name: i18next.t('table_headers.additional_message'),
          selector: 'reasonMessage'
        },
        {
          name: i18next.t('table_headers.attachments'), selector: 'attachments', 
        },
        {
          name: i18next.t('table_headers.actions'),
          selector: 'actions'
        }

      ],
      rows: [],
      filterText: "",
      filteredItems: [],
      loadedData:false
    };
    this.setCancelLeaveModalShow = this.setCancelLeaveModalShow.bind(this);
    this.onCancelLeave = this.onCancelLeave.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.subHeaderComponent = this.getSubHeaderComponent.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setAttachments = this.setAttachments.bind(this);
  }
  componentDidMount() {
    let v = new Date(moment())
    var day = 60 * 60 * 24 * 1000;
    var startDate = new Date(v.getTime() - day * 7);
    setTimeout(() => {
      this.loadData(moment(startDate).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), "all", "all")
    }, 300);
  }
  setCancelLeaveModalShow() {
    this.setState({ cancelLeaveModalShow: !this.state.cancelLeaveModalShow });
    return this.state.cancelLeaveModalShow;
  }
  loadData(start, end, type, status) {
    let tokenValue = cookie.load("token");
    let userObject = cookie.load("userData");
    let userValues = Object.values(userObject);
    let userID = userValues[0];

    axios({
      method: 'post',
      url: baseURL + '/leaves_users/' + userID,
      data: {
        type: type,
        startDate: start,
        endDate: end,
        status: status,
      },
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue,
      }
    })
      .then(response => {
        if (response && response.data) {
          let resData = this.state;
          resData.rows = response.data;
          for (let i = 0; i < resData.rows.length; i++) {
            resData.rows[i]['actions'] = this.buildRequestsTableActions(i,response.data[i].Id_leave,response.data[i].status)
            resData.rows[i]['status'] = <StatusComponenet status={resData.rows[i].status}></StatusComponenet>
            resData.rows[i].type=i18next.t(`filter_component.${resData.rows[i].type}`)
            let tempAttachments = []
            if ((response.data[i].type === 'medical' || response.data[i].type === 'marriage')) {
              for (let j = 0; j < response.data[i]['attachments'].length; j++) {
                tempAttachments.push(
                  { fileName: response.data[i]['attachments'][j].fileName, downloadLink: response.data[i]['attachments'][j].link }
                )
              }
            }
            resData.rows[i].attachments=this.setAttachments(tempAttachments)
          }
          
          this.setState({
            data: resData,
            filteredItems: this.state.rows,
            loadedData:true
          })
        }
      })
      .catch(error => {
        if(error.response!==undefined)
          this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      });

  }
  setAttachments(data) {
    if (data.length > 0)
      return (data.map((c, index) =>
        <a key={c.fileName}
          href={c.downloadLink} target="_blank">
          {c.fileName}
          <br></br>
        </a>

      ))
    else return (
      <a style={{ color: 'black' }}>
      </a>
    )
  }
  buildRequestsTableActions(index,id,status) {
    return (
      <div>
        {status==='pending'?
        <i className="fas fa-window-close" aria-hidden="true" onClick={() => this.verifyCancelRequest(index)}></i>
        :null}
        <i style={{ marginLeft: 20 }} className="fas fa-file-download" onClick={() => this.getToDownloadRequest(index,id)}></i>
      </div>
    )
  }


  verifyCancelRequest = (index) => {
    this.setState({
      requestIndexToCancel: index
    })
    let requestId = this.state.rows[index].Id_leave;
    this.setState({
      requestToCancel: requestId
    })
    this.setCancelLeaveModalShow();
  };

  onCancelLeave() {
    const leave = {
      status: this.state.status
    }
    let tokenValue = cookie.load("token");
    axios({
      method: 'put',
      url: baseURL + '/leaves_status/' + this.state.requestToCancel,
      data: leave,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue
      }
    })
      .then(response => {
        this.handleNotificationClick("Canceled successfully!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 700);
      })
      .catch(error => {
        if(error.response!==undefined)
          this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      });
  }

  getToDownloadRequest = (index,id) => {
    this.setState({
      requestIndexToDownload: index
    })
    let request = this.state.rows[index]
    this.setState({
      requestToDownload: request
    })
    let tokenValue = cookie.load("token");
    axios({
      method: 'get',
      url: baseURL + '/export_as_pdf/' + id,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenValue
      }
    })
      .then((response) => {
        window.open(response.data.downloadLink);
      });
  };

  handleNotificationClick = (msg, level) => {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        msg
      ),
      level: level,
      position: "tr",
      autoDismiss: 15
    });
  };
  getSubHeaderComponent = () => {
    return (
      <div>
        <input
          className="form-control"
          placeholder={i18next.t("search")}
          onChange={(e) => {
            let newFilterText = e.target.value;
            this.state.filteredItems = this.state.rows.filter(
              (item) =>
                (item.firstName && item.firstName.toLowerCase().includes(this.state.filterText.toLowerCase())) ||
                (item.type && item.type.toLowerCase().includes(this.state.filterText.toLowerCase())) ||
                (item.lastName && item.lastName.toLowerCase().includes(this.state.filterText.toLowerCase()))
            );
            this.setState({ filterText: newFilterText });
            if (newFilterText === "")
              this.state.filteredItems = this.state.rows;
          }}
        />
      </div>
    );
  };
  render() {
    return (
      <div className="content" style={{minHeight:500}}>
        <NotificationSystem ref="notificationSystem" style={style} />
        <CancelLeaveModal onCancelLeave={this.onCancelLeave}
          show={this.state.cancelLeaveModalShow}
          onHide={this.setCancelLeaveModalShow} />
        <FiltersComponent
          voucher={false}
          startDate={new Date(new Date(moment()).getTime() - 60 * 60 * 24 * 1000 * 7)}
          endDate={new Date(moment())}
          loadData={this.loadData}
          status={'all'}>
        </FiltersComponent>
        {this.state.loadedData ?
        <DataTable
          columns={this.state.columns}
          data={this.state.filteredItems}
          pagination
          keyField="Leave_Id"
          striped
          responsive
          subHeaderComponent={this.getSubHeaderComponent()}
          subHeader
          noHeader
        />:
        <LoadingComponent />}
      </div>
    )
  }
}

export default AllRequestsSupervisor;