import React, { Component } from "react";
import { Roller } from "react-awesome-spinners";
import '../../assets/css/Employees.css';
class LoadingComponent extends Component {
  render() {
    return (
      <div className="content" style={{ width: "0%", margin: "0 auto" }}>
        <Roller />
      </div>
    );
  }
}

export default LoadingComponent;
