import React, { Component } from "react";
import EmployeesNavbar from "./EmployeesNavbar";
import '../../assets/css/PageComponents.css';

class Employees extends Component {
    render() {
        return (
            <div className="page-container">
              <EmployeesNavbar></EmployeesNavbar>
            </div>
        );
    }
}

export default Employees;
