import React, { Component } from "react";
import i18next from "i18next";
import ReactExport from "react-data-export";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class SubHeaderExport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: props.data,
			allData: props.allData,
			filterText: props.filterText,
		};
		this.getSubHeaderComponent = this.getSubHeaderComponent.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.allData !== this.state.allData) {
			this.setState({ allData: nextProps.allData });
		}
		if (nextProps.filterText !== this.state.filterText) {
			this.setState({ filterText: nextProps.filterText });
		}
	}

	getSubHeaderComponent = () => {
		return (
			<div style={{ width: "inherit" }}>
				<ExcelFile
					style={{ float: "left" }}
					filename={i18next.t("requests") + "-" + moment().format("YYYY-MM-DD")}
					element={
						<button className="btn btn-danger btn-fill">
							<i className="fas fa-file-excel"></i> Export{" "}
						</button>
					}
				>
					<ExcelSheet data={this.state.data} name="Employees">
						<ExcelColumn label={i18next.t("table_headers.first_name")} value="firstName" />
						<ExcelColumn label={i18next.t("table_headers.last_name")} value="lastName" />
						<ExcelColumn label={i18next.t("table_headers.start_date")} value="startDate" />
						<ExcelColumn label={i18next.t("table_headers.end_date")} value="stopDate" />
						<ExcelColumn label={i18next.t("table_headers.status")} value="statusDownload" />
						<ExcelColumn label={i18next.t("table_headers.type")} value="type" />
					</ExcelSheet>
				</ExcelFile>
				<input
					style={{ float: "right", width: 160 }}
					className="form-control"
					placeholder={i18next.t("search")}
					onChange={(e) => {
						let newFilterText = e.target.value;

						let newItems = this.state.allData.filter(
							(item) =>
								(item.firstName && item.firstName.toLowerCase().includes(newFilterText.toLowerCase())) ||
								// (item.type && item.type.toLowerCase().includes(newFilterText.toLowerCase()))
								(item.lastName && item.lastName.toLowerCase().includes(newFilterText.toLowerCase()))
						);
						this.props.updateData(newFilterText, newItems);
					}}
					value={this.state.filterText}
				/>
			</div>
		);
	};
	render() {
		return <div style={{ width: "inherit" }}>{this.getSubHeaderComponent()}</div>;
	}
}
export default SubHeaderExport;
