import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { baseURL } from 'constants.js';
import axios from "axios";
import cookie from 'react-cookies';
import { map } from 'constants.js';
import i18next from 'i18next';
if (localStorage.getItem('language') === 'ro')
    moment.locale('ro', {
        months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembire'.split('_'),
        monthsParseExact: true,
        weekdays: 'Duminică_Luni_Marți_Miercuri_Joi_Vineri_Sămbătă'.split('_'),
        weekdaysShort: 'Dum._Lun._Mar._Mi._J._Vi._Sâm.'.split('_'),
        weekdaysMin: 'Du_Lu_Ma_Mi_J_Vi_Sâ'.split('_'),
        weekdaysParseExact: true,
    });
const localizer = momentLocalizer(moment)
const ColoredDateCellWrapper = ({
    range,
    value,
    children
}) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return (
        React.cloneElement(React.Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: value < now ? '#e5e5e5' :
                    { backgroundColor: value = now ? "#63d8f1" : 'white', }
            }
        }
        ))
};

class CalendarAdminLeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: []
        }
        this.arrangeEvents = this.arrangeEvents.bind(this)
        setTimeout(() => {
            let tokenValue = cookie.load("token");
            let userObject = cookie.load("userData");
            let userValues = Object.values(userObject);
            let userID = userValues[0];
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + tokenValue,
                }
            };


            axios.post(baseURL + '/company_holidays', {}, axiosConfig)
                .then(response => {
                    if (response && response.data) {
                        this.arrangeEvents(response, 'holiday')
                    }
                })
                .catch(error => {
                    if (error.response.data !== undefined)
                        this.handleNotificationClick(i18next.t(error.response.data.message), "error")
                });
            if (cookie.load("userData").userRole === "admin")
                axios.post(baseURL + '/leaves_users',{"type": "all","status": "accepted"},axiosConfig)
                    .then(response => {
                        if (response && response.data) {
                            this.arrangeEvents(response, null)
                        }
                    })
                    .catch(error => {
                        if (error.response.data !== undefined)
                            this.handleNotificationClick(i18next.t(error.response.data.message), "error")
                    });
            else
                axios.get(baseURL + '/accepted_leaves_supervisor/' + cookie.load("userData").id, axiosConfig)
                    .then(response => {
                        if (response && response.data) {
                            this.arrangeEvents(response, null)

                        }
                    })
                    .catch(error => {
                        if (error.response.data !== undefined)
                            this.handleNotificationClick(i18next.t(error.response.data.message), "error")
                    });
            // let events = this.state.events;
            // for (let i = 0; i < props.pendingRequests.length; i++) {
            //     events.push({
            //         title: props.pendingRequests[i].firstName,
            //         startDate: new Date(props.pendingRequests[i].startDate),
            //         endDate: new Date(props.pendingRequests[i].stopDate),
            //         hexColor: map['pending']
            //     });
            // }
            if (cookie.load("userData").userRole === "admin")
                axios({
                    method: 'post',
                    url: baseURL + '/leaves_users',
                    data: {"type": "all","status": "pending",},
                    headers: axiosConfig.headers})
                    .then(response => {
                        if (response && response.data) {
                            this.arrangeEvents(response, 'pending')
                        }
                    })
                    .catch(error => {
                        if (error.response !== undefined)
                            this.handleNotificationClick(i18next.t(error.response.data.message), "error")
                    });
            else
                axios.get(baseURL + '/pending_leaves/' + userID, axiosConfig)
                    .then(response => {
                        this.arrangeEvents(response, 'pending')
                    })
                    .catch(error => {
                        if (error.response !== undefined)
                            this.handleNotificationClick(i18next.t(error.response.data.message), "error")
                    });
        }, 400);
    }

    arrangeEvents(response, type) {
        let events = this.state.events;

        for (let i = 0; i < response.data.length; i++) {
            events.push({
                title: type === 'holiday' ? response.data[i].name : response.data[i].firstName+" "+response.data[i].lastName,
                startDate: new Date(response.data[i].startDate),
                endDate: new Date(response.data[i].stopDate),
                hexColor: type ? map[type] : map[response.data[i].type],
                allDay: true
            });
        }
        this.setState({ events: events });
    }

    render() {

        return (
            <div className="content">
                <div>
                    <Calendar style={{ height: 600 }}
                        localizer={localizer}
                        events={this.state.events}
                        hideTimeIndicator
                        startAccessor="startDate"
                        endAccessor="endDate"
                        views={['month', 'week', 'day']}
                        messages={{
                            month: i18next.t('calendar.month'),
                            day: i18next.t('calendar.day'),
                            today: i18next.t('calendar.today'),
                            week: i18next.t('calendar.week'),
                            previous: ' < ',
                            next: ' > '
                        }}
                      
                        eventPropGetter={
                            (event) => {
                                
                                var style = {
                                    backgroundColor: event.hexColor,
                                    borderRadius: '10px',
                                    opacity: 0.8,
                                    color: 'black',
                                    border: '0px',
                                };
                                return {
                                    style: style
                                };
                            }
                        }
                        components={{
                            dateCellWrapper: ColoredDateCellWrapper
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default CalendarAdminLeader;
