import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

const cardStyle = {
  backgroundColor: "#d3eef9",
  borderRadius: "5px",
  minHeight:"180px"
  // boxShadow: "5px 5px 15px #cbced1"
}

const contentStyle = {
  padding: "10%"
}

const typeDaysStyle = {
  fontSize: "20px",
  fontWeight: "bold",
  marginTop: "6%"
}

const numberDaysStyle = {
  color: "black",
  fontSize: "40px",
  textShadow: "5px 5px 15px white"
}

export class DaysCard extends Component {
  render() {
    return (
      <div style={cardStyle} className="card">
        <div style={contentStyle} className="content">
          <Row>
            <Col xs={4}>
              <div style={numberDaysStyle} className="numbers text-center">
                {this.props.days}
              </div>
            </Col>
            <Col xs={8}>
              <div style={typeDaysStyle} className="text-center">
                {this.props.typeDays}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DaysCard;