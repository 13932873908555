import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import Moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import i18next from 'i18next';
moment.locale('ko', {
    week: {
        dow: 1,
        doy: 1,
    },
});

if (localStorage.getItem('language') === 'ro')
    moment.locale('ro', {
        months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembire'.split('_'),
        monthsParseExact: true,
        weekdays: 'Duminică_Luni_Marți_Miercuri_Joi_Vineri_Sămbătă'.split('_'),
        weekdaysShort: 'Dum._Lun._Mar._Mi._J._Vi._Sâm.'.split('_'),
        weekdaysMin: 'Du_Lu_Ma_Mi_J_Vi_Sâ'.split('_'),
        weekdaysParseExact: true,
    });
const localizer = momentLocalizer(moment)
const ColoredDateCellWrapper = ({
    range,
    value,
    children
}) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return (
        React.cloneElement(React.Children.only(children), {
            style: {
                ...children.style,
                backgroundColor: value < now ? '#e5e5e5' :
                    { backgroundColor: value = now ? "#63d8f1" : 'white', }
            }
        }
        ))
};

class CalendarEmployee extends Component {
  
    handleLangChange = ({ start, end }) => {
        var date=end;
        if(start!==end)
            date.setDate(date.getDate() + 1)
        let newEv = {
            'title': 'New holiday',
            'startDate': new Date(start),
            'endDate': new Date(date),
            'hexColor': 'red'
        }
        let startDate = Moment(start).format('YYYY-MM-DD');
        date=end;
        if(start!==end)
            date.setDate(date.getDate() - 1)
        let endDate = Moment(date).format('YYYY-MM-DD');
        let period = startDate + ' - ' + endDate
        this.props.onSelectLanguage(period, newEv);
    }

    render() {
        return (
            <div className="content">
                <div>
                    <Calendar className="center-block"style={{ height: 500,width:'95%'}}
                        selectable
                        localizer={localizer}
                        events={this.props.events}
                        startAccessor="startDate"
                        endAccessor="endDate"
                        views={ ['month','week', 'day']}
                        messages={{
                            month: i18next.t('calendar.month'),
                            day: i18next.t('calendar.day'),
                            today: i18next.t('calendar.today'),
                            week:i18next.t('calendar.week'),
                            previous:'<',
                            next:'>'
                          }}
                        onSelectSlot={this.handleLangChange}
                        eventPropGetter={
                            (event) => {
                                var style = {
                                    backgroundColor: event.hexColor,
                                    borderRadius: '10px',
                                    opacity: 0.8,
                                    color: 'black',
                                    border: '0px',
                                };
                                return {
                                    style: style
                                };
                            }
                        }

                        components={{
                            dateCellWrapper: ColoredDateCellWrapper
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default CalendarEmployee;
