import React, { Component } from "react";
import { NavItem, Nav, DropdownButton } from "react-bootstrap";
import cookie from 'react-cookies';
import i18n from "../Internalization/i18n.js"
import i18next from 'i18next';
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import { LightTooltip } from "components/CustomTooltip.js";
const divStyle = {
  padding: 8,
  fontWeight: 500
}
class AdminNavbarLinks extends Component {

  constructor() {
    super();
    this.state = {
      userData: {
        _notificationSystem: null
      }
    }
    this.logout = this.logout.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
  }
  logout() {

    // let tokenValue = cookie.load("token");
    // let axiosConfig = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer ' + tokenValue,
    //   }
    // };

    // axios.get(baseURL + '/logout', axiosConfig)
    //   .then(response => {
    //     this.handleNotificationClick(i18next.t(response.data.message), "success")
    //     if (response) {
          cookie.remove("token", { path: '/' });
          cookie.remove("userData", { path: '/' });
          window.location.href = '/';
      //   }
      // })
      // .catch(error => {
      //   if(error.response!==undefined)
      //     this.handleNotificationClick(i18next.t(error.response.data.message),"error")
      // });
  }
  handleNotificationClick = (msg, level) => {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-bell" />,
      message: (
        msg
      ),
      level: level,
      position: "tr",
      autoDismiss: 15,
    });
  };
  render() {
    return (
      <div>
        <NotificationSystem  className="alert alert-success" ref="notificationSystem" style={style} />
        <Nav pullRight>
          <NavItem eventKey={2} >
            <LightTooltip title="Change language">
            <DropdownButton key={4} id={'exampleId'} style={{ border: 'none', margin: 0, padding: 0 }} title={<i className="fas fa-globe"></i>}>
              <div style={divStyle} onClick={() => {  i18n.changeLanguage('ro'); localStorage.setItem('language', 'ro');window.location.reload(); }}>RO</div>
              <div style={divStyle} onClick={() => { i18n.changeLanguage('en'); localStorage.setItem('language', 'en'); window.location.reload(); }}>EN</div>
            </DropdownButton>
            </LightTooltip>
          </NavItem>

          <NavItem eventKey={3} href="#" onClick={this.logout}>
           {i18next.t("log_out")}
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
