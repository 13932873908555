import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "constants.js";
import cookie from "react-cookies";
import { DropdownButton, Modal } from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import NotificationSystem from "react-notification-system";
import { style } from "variables/Variables.jsx";
import DataTable from "react-data-table-component";
import moment from "moment";
import i18next from "i18next";
const divStyle = {
	padding: 8,
	fontWeight: 500,
	cursor: "pointer",
};
const currentYear = moment().format("YYYY");
const nextYear = moment(new Date().getTime() + 60 * 60 * 24 * 1000 * 365).format("YYYY");
const nextTwoYears = moment(new Date().getTime() + 60 * 60 * 24 * 1000 * 365 * 2).format("YYYY");
const lastYear = moment(new Date().getTime() - 60 * 60 * 24 * 1000 * 365).format("YYYY");
const lastTwoYears = moment(new Date().getTime() - 60 * 60 * 24 * 1000 * 365 * 2).format("YYYY");
function DeleteHolidayModal({ onDelete, ...props }) {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter">
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">{i18next.t("settings_page.delete_holiday")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3 id="deleteBody">{i18next.t("settings_page.delete_holiday_check")}</h3>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton onClick={onDelete}>{i18next.t("modal_buttons.yes")}</CustomButton>
				<CustomButton onClick={props.onHide}> {i18next.t("modal_buttons.close")}</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

class SettingsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_notificationSystem: null,
			deleteHolidayModalShow: false,
			holidayIndexToDelete: -1,
			holidayToDelete: "",
			year: moment().format("YYYY"),
			columns: [
				{
					name: i18next.t("settings_page.name"),
					selector: "name",
				},
				{
					name: i18next.t("table_headers.start_date"),
					selector: "startDate",
				},
				{
					name: i18next.t("table_headers.end_date"),
					selector: "stopDate",
				},
				{
					name: i18next.t("table_headers.action"),
					selector: "actions",
				},
			],
			rows: [],
			filterText: "",
			filteredItems: [],
		};
		this.setDeleteHolidayModalShow = this.setDeleteHolidayModalShow.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.handleNotificationClick = this.handleNotificationClick.bind(this);
		this.subHeaderComponent = this.getSubHeaderComponent.bind(this);
		this.getHolidaysPerYear = this.getHolidaysPerYear.bind(this);
	}

	setDeleteHolidayModalShow() {
		this.setState({ deleteHolidayModalShow: !this.state.deleteHolidayModalShow });
		return this.state.deleteHolidayModalShow;
	}

	getHolidaysPerYear(year) {
		let tokenValue = cookie.load("token");
		axios({
			method: "post",
			url: baseURL + "/company_holidays",
			data: { year: year },
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				if (response && response.data) {
					let resData = this.state;
					resData.rows = response.data;
					for (let i = 0; i < resData.rows.length; i++) {
						resData.rows[i]["actions"] = this.buildUsersTableActions(i);
					}
					this.setState({
						data: resData,
						filteredItems: this.state.rows,
					});
				}
			})
			.catch((error) => console.log(error));
	}

	componentDidMount() {
		setTimeout(() => {
			this.getHolidaysPerYear(currentYear);
		}, 200);
	}

	buildUsersTableActions(index) {
		return (
			<div id="activateBtn">
				<i className="fa fa-trash" aria-hidden="true" onClick={() => this.verifyDeleteHoliday(index)}></i>
			</div>
		);
	}

	verifyDeleteHoliday = (index) => {
		this.setState({
			holidayIndexToDelete: index,
		});
		let holidayId = this.state.rows[index].id;
		this.setState({
			holidayToDelete: holidayId,
		});
		this.setDeleteHolidayModalShow();
	};

	onDelete() {
		let tokenValue = cookie.load("token");
		axios({
			method: "delete",
			url: baseURL + "/holidays/" + this.state.holidayToDelete,
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + tokenValue,
			},
		})
			.then((response) => {
				this.handleNotificationClick("Deleted successfully!", "success");
				setTimeout(() => {
					window.location.reload();
				}, 700);
			})
			.catch((error) => {
				if (error.response !== undefined) this.handleNotificationClick(i18next.t(error.response.data.message), "error");
			});
	}

	handleNotificationClick = (msg, level) => {
		this.setState({ _notificationSystem: this.refs.notificationSystem });
		this.state._notificationSystem.addNotification({
			title: <span data-notify="icon" className="pe-7s-bell" />,
			message: msg,
			level: level,
			position: "tr",
			autoDismiss: 15,
		});
	};
	getSubHeaderComponent = () => {
		return (
			<div style={{ width: "inherit" }}>
				<DropdownButton key={1} id={"exampleId"} style={{ border: "none", margin: 0, padding: 0, float: "left", marginTop: 10 }} title={this.state.year}>
					<div
						style={divStyle}
						onClick={() => {
							this.setState({ year: currentYear });
							this.getHolidaysPerYear(currentYear);
						}}
					>
						{currentYear}
					</div>
					<div
						style={divStyle}
						onClick={() => {
							this.setState({ year: nextYear });
							this.getHolidaysPerYear(nextYear);
						}}
					>
						{nextYear}
					</div>
					<div
						style={divStyle}
						onClick={() => {
							this.setState({ year: nextTwoYears });
							this.getHolidaysPerYear(nextTwoYears);
						}}
					>
						{nextTwoYears}
					</div>
					<div
						style={divStyle}
						onClick={() => {
							this.setState({ year: lastYear });
							this.getHolidaysPerYear(lastYear);
						}}
					>
						{lastYear}
					</div>
					<div
						style={divStyle}
						onClick={() => {
							this.setState({ year: lastTwoYears });
							this.getHolidaysPerYear(lastTwoYears);
						}}
					>
						{lastTwoYears}
					</div>
				</DropdownButton>
				<input
					style={{ float: "right", width: 160 }}
					className="form-control"
					placeholder={i18next.t("search")}
					onChange={(e) => {
						let newFilterText = e.target.value;
						this.state.filteredItems = this.state.rows.filter((item) => item.name && item.name.toLowerCase().includes(this.state.filterText.toLowerCase()));
						this.setState({ filterText: newFilterText });
					}}
				/>
			</div>
		);
	};
	render() {
		return (
			<div className="mainDivStyle">
				<NotificationSystem ref="notificationSystem" style={style} />
				<DeleteHolidayModal onDelete={this.onDelete} show={this.state.deleteHolidayModalShow} onHide={this.setDeleteHolidayModalShow} />
				<DataTable columns={this.state.columns} data={this.state.filteredItems} pagination keyField="id" striped responsive subHeaderComponent={this.getSubHeaderComponent()} subHeader noHeader />
			</div>
		);
	}
}

export default SettingsTable;
