import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import '../../assets/css/RequestsCard.css';
import { map } from "constants.js";
import i18next from 'i18next';
export class RequestsCard extends Component {
  render() {
    return (
      <div
        className="card cardStyle"
        style={{ backgroundColor: map[this.props.type] + '40' }}
      >
        <div className="content" >
          <Row>
            <Col xs={12} >
              <Row >
                <h5 className="text-center" style={{ fontWeight: 'bold' }}>
                  {this.props.name}
                </h5>
              </Row>
              <Row>
                <h6 className="text-center">
                  {this.props.startDate}
                </h6>
              </Row>
              <Row >
                <h6 className="text-center">
                  {"(" + i18next.t(this.props.type.toUpperCase())+")"}
                </h6>
              </Row>
              <Row >
                <h6 className="text-center">
                  {this.props.message}
                </h6>
              </Row>
              <Row >
                <Col lg={6} xs={12}>
                  <Button 
                  style={{ marginTop: 10, fontWeight: 'bold', color: map[this.props.type], backgroundColor: 'white', borderColor: 'white' }} 
                  className="btn center-block"
                  onClick={()=> this.props.accept(this.props.index,'accepted')}
                  >{i18next.t("leave_management.accept")}</Button>
                </Col>
                <Col lg={6} xs={12}>
                  <Button style={{ marginTop: 10, fontWeight: 'bold', color: 'white', borderColor: map[this.props.type],backgroundColor:map[this.props.type] }}
                   className="btn center-block"
                  onClick={()=> this.props.decline(this.props.index,'rejected')}
                  >{i18next.t("leave_management.decline")}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default RequestsCard;